import { Archive, Copy, Edit, MoreVertical, Trash, Trash2, Eye } from 'react-feather';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import Swal from 'sweetalert2';
import ProgressBar from 'react-bootstrap/ProgressBar';

//Custom Action Container
const actionFormater = (data, callbackRefreshTable) => {

    const actions = data?.actions;

    const handleDuplicateBroadcast = (elem, id) => {
        Swal.fire({
            title: 'Confirmation',
            text: "Are you duplicate this broadcast?",
            icon: 'question',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, duplicate it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {
                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/duplicate';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
                    'duplicate': id
                }
            
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }
            
            }
        })
    }

    const handleDeleteBroadcast = (elem, id) => {
        // console.log('ID Delete', id)

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/purge';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                {(actions?.use_continue_editing) ? <Button as={Link} to={data?.reportBroadcastLink} className="btn btn-outline-light btn-sm" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast" style={{lineHeight:'2'}}>
                    Continue Editing
                </Button> : <></>}

                {(actions?.use_view_button) ? <Button variant="flush-dark" as={Link} to={data?.reportBroadcastLink} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <Eye />
                        </span>
                    </span>
                </Button> : <></>}

                {(actions?.use_duplicate_btn) ? <Button variant="flush-dark" data-id={data?.id} onClick={(elem) => handleDuplicateBroadcast(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Duplicate Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <Copy />
                        </span>
                    </span>
                </Button> : <></>}
                
                {(actions?.use_delete_btn) ? <Button variant="flush-dark" data-id={data?.id} onClick={(elem) => handleDeleteBroadcast(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                    <span className="icon">
                        <span className="feather-icon">
                            <Trash />
                        </span>
                    </span>
                </Button> : <></>}
               
            </div>
        </div>
    )
}


export const colJadwal = (otherActionFunction) => {
    return [
        {
            accessor: "keberangkatan",
            title: "Keberangkatan",
            elipsis: false,
            sort: true,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    var percentage = Math.round((origdata?.sisa_seat / origdata?.total_seat) * 100);

                    // console.log('percentage', percentage)
                    var variant = 'success'
                    if(percentage >= 80){
                        variant = 'danger'
                    }

                    return (<div className='progress-group'>
                        <div className='progress-info'>
                            <div className='left-info'>
                                <strong className="text-red">{origdata?.dep_tgl_formatted}</strong>
                                <span style={{fontSize:'10px'}}>{origdata?.dep_bandara}</span>
                                <span style={{fontSize:'10px'}}>{origdata?.dep_jam}</span>
                            </div>
                            <div className='right-info'>
                                <span className="label label-warning pull-right">OP</span>
                                <span className='progress-number'><b>{origdata?.sisa_seat}</b>/{origdata?.total_seat}</span>
                            </div>
                        </div>
                        <ProgressBar className='mt-2' variant={variant} animated now={percentage} />
                    </div>)
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        },
        {
            accessor: "kepulangan",
            title: "Kepulangan",
            elipsis: false,
            sort: true,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    return (<div className='progress-group'>
                        <div className='progress-info'>
                            <div className='left-info'>
                                <strong className="text-red">{origdata?.ret_tgl_formatted}</strong>
                                <span style={{fontSize:'10px'}}>{origdata?.ret_bandara}</span>
                                <span style={{fontSize:'10px'}}>{origdata?.ret_jam}</span>
                            </div>
                        </div>
                    </div>)
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        },
        {
            accessor: "maskapai_paket",
            title: "Maskapai / Paket",
            elipsis: false,
            sort: true,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    return (<div className='maskapai-airlines'>
                        <b>{origdata?.airlines}</b>
                        <span>{origdata?.nama_paket}</span>
                    </div>)
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        },
        {
            accessor: "hotel_biaya",
            title: "Hotel / Biaya",
            elipsis: false,
            sort: false,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    return (
                        <>
                        <Button onClick={(e) => {
                            if((typeof otherActionFunction?.showBiaya != 'undefined') && (otherActionFunction?.showBiaya != null)){
                                otherActionFunction?.showBiaya(origdata?.info_biaya)
                            }
                        }} className='btn btn-sm btn-secondary p-2 h-auto btn-act-jadwal'>
                            <small>DETAIL BIAYA</small>
                        </Button>
                        </>
                    )
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        },
        {
            accessor: "itinerary",
            title: "ITINERARY",
            elipsis: false,
            sort: false,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    return (
                        <>
                        <Button className='btn btn-sm btn-secondary p-2 h-auto btn-act-jadwal' href={origdata?.link_itinerary} target="_blank" rel="noopener noreferrer">
                            <small>LIHAT ITINERARY</small>
                        </Button>
                        </>
                    )
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        },
        {
            accessor: "brosur",
            title: "Brosur",
            elipsis: false,
            sort: false,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    return (
                        <>
                        <Button className='btn btn-sm btn-secondary p-2 h-auto btn-act-jadwal' href={origdata?.link_brosur} target="_blank" rel="noopener noreferrer">
                            <small>LIHAT BROSUR</small>
                        </Button>
                        </>
                    )
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        },
        {
            accessor: "manasik",
            title: "Manasik",
            elipsis: false,
            sort: false,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    return (
                    <div className='manasik-jadwal'>
                        <b>{origdata?.manasik_tgl}</b>
                        <span>→ {origdata?.manasik_jam}</span>
                    </div>
                    )
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        },
        {
            accessor: "seat",
            title: "Seat",
            elipsis: false,
            sort: true,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
                    var origdata = dataJson?.origdata

                    return (
                    <>
                    <strong className='text-green'>{origdata?.total_seat}</strong> / <span className={(parseInt(origdata?.sisa_seat) <= 5) ? 'text-danger' : 'text-success'}>{origdata?.sisa_seat}</span>
                    </>
                    )
                    console.log('origdata', origdata)

                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }

            }
        }
    ]
};