import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button, Table } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { updateTopNavTitle } from '../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';

import { colJadwal } from './DraftTableData';
import YogiTabler from '../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const JadwalIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const playerRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEditor, ChooseEditor] = useState('unlayer');
    const [show, setShow] = useState(false)
    const [dataBiaya, setDataBiaya] = useState({})
    const formRef = useRef();

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'jadwal/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const customhandlers = (data) => {
        //any handler if data loaded
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Jadwal',
            actions: {}
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className='p-3 pt-0 mt-3'>
                <YogiTabler
                    column={colJadwal({
                        showBiaya: (data) => {
                            // console.log(data)

                            setShow(true)
                            setDataBiaya(data)
                        }
                    })}
                    rowData={rowData}
                    rowsPerPage={10}
                    rowSelection={false}
                    markStarred={false}
                    searchQuery={searchTerm}
                    classes="nowrap w-100 mb-4"
                    api_endpoint={apiEndpoint}
                    api_params={{
                        'auth': apiParams?.auth,
                        'services': apiParams?.services,
                        'section': 'all',
                        'type' : 'haji'
                    }}
                    customhandlers={customhandlers}
                    emptydataconfig={{
                        heading: 'Jadwal Kosong',
                        subheading: "Silakan hubungi customer services.",
                        actions: null
                    }}
                    sortFields={[
                        {
                            title: 'Email',
                            field: 'email'    
                        },
                        {
                            title: 'Status',
                            field: 'status'    
                        }
                    ]}
                    responsive
                />

            </div>


            <Modal.Dialog>
                <Modal size='lg' show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-5'>Detail Biaya</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped responsive>
                            <thead>
                                <tr>
                                    <th rowSpan="2" className='text-center align-middle'>PAKET</th>
                                    <th colSpan="3" className='text-center align-middle'>HARGA ROOM</th>
                                    <th colSpan="2" className='text-center align-middle'>HOTEL</th>
                                    <th rowSpan="2" className='text-center align-middle'>PERLENGKAPAN &amp; HANDLING</th>
                                    <th rowSpan="2" className='text-center align-middle'>PCR &amp; KARANTINA</th>
                                    <th rowSpan="2" className='text-center align-middle'>KETERANGAN</th>
                                </tr>
                                <tr>
                                    <th className='text-center'>DOUBLE</th>
                                    <th className='text-center'>TRIPLE</th>
                                    <th className='text-center'>QUARD</th>
                                    <th className='text-center'>MADINAH</th>
                                    <th className='text-center'>MEKKAH</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dataBiaya && dataBiaya.PAKET && Object.keys(dataBiaya.PAKET).length > 0 ? (
                                    Object.keys(dataBiaya.PAKET).map((key) => (
                                        <tr key={key}>
                                        <td className='text-center vertical-middle'>{key}</td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.PACKAGE?.[0]?.price}
                                        </td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.PACKAGE?.[1]?.price}
                                        </td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.PACKAGE?.[2]?.price}
                                        </td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.HOTEL?.[0]?.name}
                                        </td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.HOTEL?.[1]?.name}
                                        </td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.PERLENGKAPAN}
                                        </td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.KARANTINA}
                                        </td>
                                        <td className='text-center vertical-middle'>
                                            {dataBiaya.PAKET[key]?.KETERANGAN}
                                        </td>
                                        </tr>
                                    ))
                                    ) : (
                                    <tr>
                                        <td colSpan="9" className='text-center vertical-middle'>No data available</td>
                                    </tr>
                                    )}
                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </Modal.Dialog>

        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(JadwalIndex);