import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import useSortableData from '../@hk-data-table/hooks/useSortableData';
import useRowSelect from '../@hk-data-table/hooks/useRowSelect';
import useTablePageSize from '../@hk-data-table/hooks/useTablePageSize';
import { ArrowsSort, Badge, SortAscending, SortDescending } from 'tabler-icons-react';
import TableFooter from '../@hk-data-table/TableFooter';
import { Col, Container, Form, Table, InputGroup, Nav, Row, Tab, Button } from 'react-bootstrap';
import HkBadge from '../@hk-badge/@hk-badge';

import { Player } from '@lordicon/react';
// const SMILEJson = require('../../views/Broadcasts/Index/iconjson/smile.json');
const SMILEJson = require('../../views/Jamaah/Umroh/iconjson/smile.json');

const YogiTabler = ({
    column,
    classes,
    striped,
    bordered,
    borderless,
    hover,
    size,
    variant,
    responsive,
    rowsPerPage,
    api_endpoint,
    api_params,
    rowSelection,
    paginatorSize,
    sortFields,
    customhandlers,
    emptydataconfig
}) => {

  const playerRef = useRef(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [refreshData, setRefreshData] = useState('');
  const [searchQ, setSearchQ] = useState('');
  const [totalData, setTotalData] = useState(-1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // You can adjust items per page as needed
  const { items, requestSort, sortConfig } = useSortableData(data);
  const { slice, range } = useTablePageSize(items, currentPage, rowsPerPage);
  const { selectAll, handleRowSelection, handleSelectAll, isRowSelected } = useRowSelect(slice, data);

  useEffect(() => {
    playerRef.current?.playFromBeginning();

    console.log('SYScurrentPage', currentPage)

    fetchData({
      page: currentPage, rowperpage: itemsPerPage, searchq: searchQ
    });
  }, [currentPage, searchQ, itemsPerPage, refreshData]);

  const fetchData = async (default_params) => {
    try {
      const api_params_final = { ...api_params, ...default_params };

      console.log('api_params_final', api_params_final)

      const bodySend = JSON.stringify(api_params_final);

      console.log('bodySend', bodySend)

      const response = await fetch(api_endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: bodySend // Sending current page to fetch data accordingly
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();

      if((typeof customhandlers != 'undefined') && (customhandlers != null) && (customhandlers != '')){
        customhandlers(result)
      }

      console.log('RESDATA', result)

      setData(result?.tabledata);
      setTotalData(result?.total_data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Logic to calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems = data;

  // console.log('data', data)
  // console.log('currentItems', currentItems)

  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  let isemptyData = ((parseInt(totalData) == 0) ? true : false)

  // console.log('isemptyData', isemptyData)
  // console.log('totalData', totalData)

  if(isemptyData){
    if(emptydataconfig){
      return (<div className='d-flex flex-column justify-content-center gap-3  align-items-center'>
      <Player ref={playerRef} size={130} icon={ SMILEJson } onComplete={() => playerRef.current?.playFromBeginning()}/>
      <h5 className='mb-0'>{emptydataconfig?.heading}</h5>
      <p>{emptydataconfig?.subheading}</p>
      <a className={emptydataconfig?.actions?.className} onClick={emptydataconfig?.actions?.onclick}>{emptydataconfig?.actions?.title}</a>
  </div>)
    }else{
      return (<></>)
    }
    
  }else{
    return (
      <div>
        <Row className="mb-3 row ps-3 pe-3" >
            <Col xs={7} mb={3}>
              <div className="d-flex gap-3">
                  <Form.Group className="d-xxl-flex d-none align-items-center mb-0">
                      <Form.Select size='sm' className="w-120p">
                          <option value={0}>Bulk actions</option>
                          <option value={1}>Move to trash</option>
                      </Form.Select>
                      <Button size="sm" variant="light" className="ms-2">Apply</Button>
                  </Form.Group>
                  {(sortFields) ? <Form.Group className="d-xxl-flex d-none align-items-center mb-0">
                      <label className="flex-shrink-0 mb-0 me-2">Sort by:</label>
                      <Form.Select size='sm' className="w-130p">
                          {sortFields.map((cols, index) => (
                            <option value={cols?.field}>{cols?.title}</option>
                          ))}
                      </Form.Select>
                  </Form.Group> : <></>}
                  
              </div>
            </Col>
            <Col xs={5} mb={3}>
              <div className='d-flex justify-content-end'>
                <Form.Label>
                      <Form.Control
                          size="sm"
                          type="search"
                          placeholder="Search"
                          value={searchQ}
                          onChange={e => setSearchQ(e.target.value)}
                      />
                  </Form.Label>
              </div>
            </Col>
        </Row> 

        <Table 
        className={classNames("hk-data-table", classes)}
        striped={striped}
        bordered={bordered}
        borderless={borderless}
        hover={hover}
        size={size}
        variant={variant}
        responsive={responsive}>
          <thead>
            <tr>
              {(rowSelection) && <th>
                  {rowSelection ? <Form.Check
                      type="checkbox"
                      className="fs-6 mb-0 d-flex justify-content-center"
                      checked={selectAll}
                      onChange={handleSelectAll}
                  />
                      :
                      <></>
                  }
  
              </th>}
              {column.map((cols, index) => (
                  <th
                      key={index}
                      onClick={() => requestSort(cols.sort, cols.accessor)}
                      className={classNames({ 'd-none': cols.hidden }, { "text-primary": sortConfig !== null && sortConfig.key === cols.accessor }, cols.className)}
                      rowSpan={cols.rowSpan}
                  >
                      <span className="d-flex">
                          <span className="flex-grow-1">
                              {cols.title}
                          </span>
                          {
                              cols.sort &&
                              <span>
                                  {(sortConfig !== null && cols.accessor === sortConfig.key)
                                      ?
                                      <>
                                          {
                                              (sortConfig.direction === 'ascending')
                                                  ?
                                                  <font color="#007D88">
                                                      <SortAscending size={14} strokeWidth={2.5} />
                                                  </font>
                                                  :
                                                  <font color="#007D88">
                                                      <SortDescending size={14} strokeWidth={2.5} />
                                                  </font>
                                          }
                                      </>
                                      :
                                      <span><ArrowsSort size={14} strokeWidth={2.5} /> </span>}
                              </span>
                          }
                      </span>
                  </th>
              ))}
              {/* Add more table headers as needed */}
            </tr>
          </thead>
          <tbody>
          {currentItems.map((item, index) => (
            <tr key={item?.extra?.id}>
              {(rowSelection) && <td>
                  <div className="fs-6 mb-0 d-flex justify-content-center">
                      {rowSelection && <Form.Check
                          type="checkbox"
                          className="fs-6 mb-0 d-flex justify-content-center"
                          checked={isRowSelected(index)}
                          onChange={() => handleRowSelection(index)}
                      />}
                  </div>
              </td>}
              {column.map((cols, index) => {
                if(cols.accessor == 'actions'){
                  return (<td>{cols.cellFormatter(item?.extra, setRefreshData)}</td>)
                }else{
                  if((typeof item?.coldata[cols?.accessor] === 'object' && item?.coldata[cols?.accessor] !== null)){
                    const colDataValue = item?.coldata[cols?.accessor]
                    if(colDataValue?.prop == 'badge'){
                      if(colDataValue?.other?.badge_icon){
                        return (<td data-sorkey={cols?.accessor} data-multibadge={'false'} key={index}><HkBadge bg={colDataValue?.other?.badge_state} soft className="m-0 d-flex gap-2 align-items-center" key={index} style={{maxWidth: 'max-content', width: 'auto !important'}}><div dangerouslySetInnerHTML={{__html: colDataValue?.other?.badge_icon}} /> {colDataValue?.text}</HkBadge></td>)
                      }else{
                        return (<td data-sorkey={cols?.accessor} data-multibadge={'false'} key={index}><HkBadge bg={colDataValue?.other?.badge_state} soft className="my-1  me-2" key={index} >{colDataValue?.text}</HkBadge></td>)
                      }
                    }else if(colDataValue?.prop == 'multi_badge'){
                      return (<td data-sorkey={cols?.accessor} data-multibadge={'true'} key={index}>
                        <div className='d-flex gap-2'>
                          {colDataValue?.text?.map((_col, _index) => (
                            <HkBadge bg={colDataValue?.other[_index]?.badge_state} soft className="m-0 d-flex gap-2 align-items-center" key={_index} > <div dangerouslySetInnerHTML={{__html: colDataValue?.other[_index]?.badge_icon}} /> {_col}</HkBadge>
                          ))}
                        </div>
                      </td>)
                    }
                  }else if(cols.cellFormatter){
                    const dataExtra = {
                      text: item?.coldata[cols?.accessor],
                      id: item?.extra?.id,
                      otherprop: item?.coldata,
                      origdata: item?.extra?.props
                    };

                    // console.log('dataExtra', dataExtra)
                    return (<td data-sorkey={cols?.accessor} key={index}>{cols.cellFormatter(JSON.stringify(dataExtra))}</td>)
                  }else{
                    if(cols?.elipsis){
                      return (<td data-sorkey={cols?.accessor} key={index}><span className='text-truncate' style={{maxWidth: '250px', display: 'inline-block'}}>{item?.coldata[cols?.accessor]}</span></td>)
                    }else{
                      return (<td data-sorkey={cols?.accessor} key={index}>{item?.coldata[cols?.accessor]}</td>)
                    }
                  }
                  
                  
                }
              })}
            </tr>
          ))}
          </tbody>
      </Table>
  
        {/* Pagination */}
        {rowsPerPage && <TableFooter
                  range={range}
                  slice={slice}
                  setPage={setCurrentPage}
                  setPerPage={setItemsPerPage}
                  page={currentPage}
                  totalRows={totalData}
                  paginatorSize={paginatorSize}
                  perpage={itemsPerPage}
              />}
  
      </div>
    );
  }
  
};

YogiTabler.propTypes = {
    column: PropTypes.array.isRequired,
    classes: PropTypes.string,
    striped: PropTypes.bool,
    bordered: PropTypes.bool,
    borderless: PropTypes.bool,
    hover: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
    responsive: PropTypes.bool,
    api_endpoint: PropTypes.string.isRequired,
    api_params: PropTypes.object.isRequired,
    rowSelection: PropTypes.bool,
    rowsPerPage: PropTypes.number,
    paginatorSize: PropTypes.string,
    sortFields: PropTypes.array,
    customhandlers: PropTypes.any,
    emptydataconfig: PropTypes.any
}

export default YogiTabler;