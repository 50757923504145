import React, { useState, useRef, useEffect } from 'react';
import { Button, Card, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Eye, EyeOff } from 'react-feather';
import { Link } from 'react-router-dom';
import CommanFooter1 from '../../CommanFooter1';

//Image
import logo from '../../../../assets/dist/img/logo-light.png';

const LoginClassic = (props) => {
    const formRef = useRef();

    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const logo = 'https://alhijazindonesia.com/wp-content/uploads/2023/09/alhijazindowisata.png';

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        // const data = {};

        const data = {};
        formData.forEach((value, key) => {
            const keyRegex = /(\w+)\[(\{\{[\w\s]+\}\}|\w+)\]/; // Updated regular expression to match keys with square brackets and handlebars notation
            const match = key.match(keyRegex);
        
            if (match) {
                const objKey = match[1]; // Extract the key before square brackets
                const subKey = match[2]; // Extract the key inside square brackets or handlebars notation
        
                if (!data[objKey]) {
                    data[objKey] = {};
                }
        
                if (!Array.isArray(data[objKey][subKey])) {
                    data[objKey][subKey] = [];
                }
        
                data[objKey][subKey].push(value);
            } else {
                if (!data[key]) {
                    data[key] = value;
                    return;
                }
                if (!Array.isArray(data[key])) {
                    data[key] = [data[key]];
                }
                data[key].push(value);
            }
        });

        console.log('data', data)

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'auth/login';
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(apiEndpoint, requestOptions)
        .then(async(response) => {
            var resJson = await response.json()

            if(resJson?.status){
                const rgd_token = resJson?.rgd_token
                const svc_token = resJson?.svc_token
                const head_info = JSON.stringify(resJson?.head_info)

                localStorage.setItem('rgd_token', rgd_token);
                localStorage.setItem('svc_token', svc_token);
                localStorage.setItem('head_info', head_info);

                window.location.href = '/dashboard'
            }else{
                
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));


        // props.history.push("/");
    }

    return (
        <div className="hk-pg-wrapper pt-0 pb-xl-0 pb-5">
            <div className="hk-pg-body pt-0 pb-xl-0">
                <Container>
                    <Row>
                        <Col sm={10} className="position-relative mx-auto">
                            <div className="auth-content py-8">
                                <Form ref={formRef} className="w-100" onSubmit={e => handleSubmit(e)}>
                                    <Row>
                                        <Col lg={5} md={7} sm={10} className="mx-auto">
                                            <div className="text-center mb-7">
                                                <Link to="/" className="navbar-brand me-0">
                                                    <img className="brand-img d-inline-block" src={logo} style={{width: '150px'}}  alt="brand" />
                                                </Link>
                                            </div>
                                            <Card className="card-lg card-border">
                                                <Card.Body>
                                                    <h4 className="mb-4 text-center">Sign in to continue to HijazPro.com.</h4>
                                                    <Row className="gx-3">
                                                        <Col as={Form.Group} lg={12} className="mb-3">
                                                            <div className="form-label-group">
                                                                <Form.Label>User Name</Form.Label>
                                                            </div>
                                                            <Form.Control placeholder="Enter email ID" name='email' type="text" value={userName} onChange={e => setUserName(e.target.value)} />
                                                        </Col>
                                                        <Col as={Form.Group} lg={12} className="mb-3">
                                                            <div className="form-label-group">
                                                                <Form.Label>Password</Form.Label>
                                                                <Link to="/auth/forgot-password" className="fs-7 fw-medium">Forgot Password ?</Link>
                                                            </div>
                                                            <InputGroup className="password-check">
                                                                <span className="input-affix-wrapper">
                                                                    <Form.Control placeholder="Enter your password" name='password' value={password} onChange={e => setPassword(e.target.value)} type={showPassword ? "text" : "password"} />
                                                                    <Link to="#" className="input-suffix text-muted" onClick={() => setShowPassword(!showPassword)} >
                                                                        <span className="feather-icon">
                                                                            {
                                                                                showPassword
                                                                                    ?
                                                                                    <EyeOff className="form-icon" />
                                                                                    :
                                                                                    <Eye className="form-icon" />
                                                                            }

                                                                        </span>
                                                                    </Link>
                                                                </span>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                    <div className="d-flex justify-content-center">
                                                        <Form.Check id="logged_in" className="form-check-sm mb-3" >
                                                            <Form.Check.Input type="checkbox" defaultChecked />
                                                            <Form.Check.Label className="text-muted fs-7">Keep me logged in</Form.Check.Label>
                                                        </Form.Check>
                                                    </div>
                                                    <Button variant="primary" type="submit" className="btn-uppercase btn-block">Login</Button>
                                                    {/* <p className="p-xs mt-2 text-center">New to HijazPro? <Link to="/register"><u>Create new account</u></Link></p> */}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Page Footer */}
            <CommanFooter1 />
        </div>

    )
}

export default LoginClassic
