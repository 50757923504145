import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { updateTopNavTitle } from '../../redux/action/Header';
import { useParams, useHistory } from 'react-router-dom';

// import HkDataTable from '../../../components/@hk-data-table';
import YogiTabler from '../../components/@yogi-data-table';
import { colReminder } from './DraftTableData';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');


const DashboardIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const maxCharLen = 2000
    const playerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [key, setKey] = useState('all');
    const [rowData, setRowData] = useState([]);
    const [show, setShow] = useState(false)
    const [sendAt, setSendAt] = useState(new Date());
    const [refreshTable, setRefreshTable] = useState('')
    const [currentCharLen, setCurrentCharLen] = useState(0)
    const [reminderId, setReminderId] = useState('')

    const [reminderName, setReminderName] = useState('')
    const [jamaahName, setJamaahName] = useState('')
    const [jamaahWaNumber, setJamaahWaNumber] = useState('')
    const [reminderContent, setReminderContent] = useState('')
    const [tipeProspek, setTipeProspek] = useState('')
    const [sentTo, setSentTo] = useState('myself')

    const formRef = useRef();

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'reminder/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const offsetToRangeCharacterMove = (el, offset) => {
        return offset - (el.value.slice(0, offset).split("\r\n").length - 1);
    }
    const setSelection = (el, start, end) => {
        if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
            el.selectionStart = start;
            el.selectionEnd = end;
        } else if (typeof el.createTextRange != "undefined") {
            var range = el.createTextRange();
            var startCharMove = offsetToRangeCharacterMove(el, start);
            range.collapse(true);
            if (start == end) {
                range.move("character", startCharMove);
            } else {
                range.moveEnd("character", offsetToRangeCharacterMove(el, end));
                range.moveStart("character", startCharMove);
            }
            range.select();
        }
    }

    const getInputSelection = (el) => {
        var start = 0, end = 0, normalizedValue, range,
                textInputRange, len, endRange;

        if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
            start = el.selectionStart;
            end = el.selectionEnd;
        } else {
            range = document.selection.createRange();

            if (range && range.parentElement() == el) {
                len = el.value.length;
                normalizedValue = el.value.replace(/\r\n/g, "\n");

                // Create a working TextRange that lives only in the input
                textInputRange = el.createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                // Check if the start and end of the selection are at the very end
                // of the input, since moveStart/moveEnd doesn't return what we want
                // in those cases
                endRange = el.createTextRange();
                endRange.collapse(false);

                if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                    start = end = len;
                } else {
                    start = -textInputRange.moveStart("character", -len);
                    start += normalizedValue.slice(0, start).split("\n").length - 1;

                    if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                        end = len;
                    } else {
                        end = -textInputRange.moveEnd("character", -len);
                        end += normalizedValue.slice(0, end).split("\n").length - 1;
                    }
                }
            }
        }

        return {
            start: start,
            end: end
        };
    }

    const insertTextAtCaret = (el, text) => {
        var pos = getInputSelection(el).end;
        var newPos = pos + text.length;
        var val = el.value;
        el.value = val.slice(0, pos) + text + val.slice(pos);
        setSelection(el, newPos, newPos);
    }

    const insertAtCursor = (myField, myValue1, myValue2) => {
        // IE support
        if (document.selection) {
          myField.focus();
          let sel = document.selection.createRange();
          sel.text = myValue1 + sel.text + myValue2;
        } else if (myField.selectionStart || myField.selectionStart === 0) {
          // MOZILLA/NETSCAPE support
          let startPos = myField.selectionStart;
          let endPos = myField.selectionEnd;
          myField.value = myField.value.substring(0, startPos) + myValue1 + myField.value.substring(startPos, endPos) + myValue2 + myField.value.substring(endPos, myField.value.length);
          myField.selectionStart = startPos + myValue1.length;
          myField.selectionEnd = endPos + myValue2.length;
        } else {
          myField.value += myValue1 + myValue2;
        }
    }

    function processCounting(text) {
        const chars = text.match(/(?:[^\r\n]|\r(?!\n))/g);
        const text_without_space = text.replace(/\s+/g, '');
        let words;
        const matches = text_without_space.match(/([^\x00-\x7F\u2013\u2014])+/gi);
        const latin_only = (matches === null);
      
        if (!latin_only) {
          words = text.match(/\S+/g);
        } else {
          words = text
            .replace(/[;!:—\/]/g, ' ')
            .replace(/\.\s+/g, ' ')
            .replace(/[^a-zA-Z\d\s&:,]/g, '')
            .replace(/,([^0-9])/g, ' $1')
            .match(/\S+/g);
        }
      
        return chars ? chars.length : 0;
      }
      
    const CountingCharacter = (e) => {
        const text = e.target.value;
        const count = processCounting(text);
        setCurrentCharLen(count);
    }  

    const ClickToolbar = (e) => {
        e.preventDefault();

        const element = e.currentTarget;
        document.querySelectorAll('.target-trigger-dropdown').forEach(dropdown => {
          dropdown.style.display = 'none';
        });
      
        const yourTextarea = element.closest('.area-editor-toolbar').querySelector("textarea");
      
        switch (element.dataset.tool) {
          case "bold":
            insertAtCursor(yourTextarea, "*", "*");
            break;
          case "italic":
            insertAtCursor(yourTextarea, "_", "_");
            break;
          case "strikethrough":
            insertAtCursor(yourTextarea, "~", "~");
            break;
          case "fullname":
            insertTextAtCaret(yourTextarea, "[fullname]");
            break;
          case "firstname":
            insertTextAtCaret(yourTextarea, "[firstname]");
            break;
          case "lastname":
            insertTextAtCaret(yourTextarea, "[lastname]");
            break;
          case "emoji":
            // window.open('https://getemoji.com/', '_blank');
            break;
          default:
            insertTextAtCaret(yourTextarea, "[" + element.dataset.tool + "]");
        }
      
        yourTextarea.dispatchEvent(new Event('keyup'));
    }

    const cek_size = (event) => {
        const elem = event.target;
        const file = elem.files[0];
        const $size = file.size;
    
        if (file) {
            const validTypes = [
                'image/png',
                'image/jpg',
                'image/jpeg',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
                'application/pdf',
                'video/mp4'
            ];
        
            if ($size <= 1048576) {
                if (validTypes.includes(file.type)) {
                    if (
                        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        file.type === 'application/vnd.ms-excel' ||
                        file.type === 'application/pdf' ||
                        file.type === 'video/mp4'
                    ) {
                        console.log('IS FILE');
                    } else {
                        console.log('IS PICTURE');
                    }
            
                    window.onbeforeunload = () => 'Cancel Reload';
                } else {
                console.log('INVALID FILE FORMAT');

                    Swal.fire("Format File Invalid", "Silahkan pilih file dengan format JPEG/JPG/PNG/PDF/XLS/XSLX/MP4.", "error").then(function() {
                        elem.value = null;
                    });
                }
            } else {
                console.log('IS TOO BIG');
                Swal.fire("That's too big", "Maximum file size is 1MB.", "error").then(function() {
                    elem.value = null;
                });
            }
        }
    }

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();

        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (key === 'file') {
                data[key] = value; // Append file directly
                return;
            }
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

        const authValue = apiParams?.auth;
        const servicesValue = apiParams?.services;

        if (!data['auth']) {
            data['auth'] = authValue;
        } else {
            if (!Array.isArray(data['auth'])) {
                data['auth'] = [data['auth']];
            }
            data['auth'].push(authValue);
        }

        if (!data['services']) {
            data['services'] = servicesValue;
        } else {
            if (!Array.isArray(data['services'])) {
                data['services'] = [data['services']];
            }
            data['services'].push(servicesValue);
        }

        console.log(data);

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'reminder/save';
        const requestOptions = {
            method: 'POST',
            // Note: No need to set 'Content-Type' header for FormData
            body: formData, 
        };

        fetch(apiEndpoint, requestOptions)
            .then(async (response) => {
                var resJson = await response.json();

                if (resJson?.status) {
                    setShow(false);
                    Swal.fire("Successfuly", resJson?.message, "success").then(function() {
                        setRefreshTable(new Date().getTime().toString());
                    });
                } else {
                    Swal.fire('Analyzing Error', resJson?.message, 'error');
                }
            })
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
    }

    const createNewReminder = () => {
        setShow(true)

        setSendAt(new Date())

        setReminderId('')
        setReminderName('')
        setJamaahName('')
        setJamaahWaNumber('')
        setReminderContent('')
        setTipeProspek('')
        setSentTo('myself')
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Reminder',
            actions: {
                title: 'Tambah Reminder',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: () => {
                    createNewReminder()
                }
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();
        // eslint-disable-next-line react-hooks/exhaustive-deps

        setRefreshTable(new Date().getTime().toString())
    }, [])
    return (
        <>
            <div>
                <Tab.Container activeKey={key}>
                    {/* Page Header */}
                    <div className="hk-pg-header pg-header-wth-tab pt-0">
                        <Nav variant="tabs" className="nav-light nav-line nav nav-tabs mt-1">
                            <Nav.Item>
                                <Nav.Link eventKey="all" onClick={(e) => setKey('all')}>
                                    <span className="nav-link-text">Semua</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="prospekumroh" onClick={(e) => setKey('prospekumroh')}>
                                    <span className="nav-link-text">Prospek Umroh</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="prospekhaji" onClick={(e) => setKey('prospekhaji')}>
                                    <span className="nav-link-text">Prospek Haji</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* /Page Header */}
                    {/* Page Body */}
                    <div className="hk-pg-body p-0 m-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="all">

                            {(refreshTable) ?  <YogiTabler
                                column={colReminder({
                                    onEditFunction: (data) => {
                                        console.log('DATA', data)
                                        setReminderId(data?.id)
                                        setReminderName(data?.props?.reminder_title)
                                        setJamaahName(data?.props?.reminder_jamaah_name)
                                        setJamaahWaNumber(data?.props?.reminder_wa_number)
                                        setReminderContent(data?.props?.reminder_content)
                                        setTipeProspek(data?.props?.reminder_type)
                                        setSentTo(data?.props?.reminder_send_to)
                                        setSendAt(new Date(data?.props?.reminder_send_at));
                                        setShow(true)
                                    }
                                })}
                                rowData={rowData}
                                rowsPerPage={10}
                                rowSelection={true}
                                markStarred={false}
                                searchQuery={searchTerm}
                                classes="nowrap w-100 mb-4"
                                api_endpoint={apiEndpoint}
                                emptydataconfig={{
                                    heading: 'Anda belum memiliki data Reminder Leads/Prospek',
                                    subheading: "Klik tombol Tambah Jamaah untuk input data Reminder baru.",
                                    actions: {
                                        className: 'btn btn-primary',
                                        title: 'Tambah Reminder',
                                        onclick: (elem) => {
                                            createNewReminder()
                                        }
                                    }
                                }}
                                api_params={{
                                    'auth': apiParams?.auth,
                                    'services': apiParams?.services,
                                    'section': 'all'
                                }}
                                sortFields={[
                                    {
                                        title: 'Name',
                                        field: 'name'    
                                    },
                                    {
                                        title: 'No WhatsApp',
                                        field: 'no_whatsapp'    
                                    },
                                    {
                                        title: 'No Paspor',
                                        field: 'no_paspor'    
                                    },
                                    {
                                        title: 'Paket',
                                        field: 'package'    
                                    },
                                    {
                                        title: 'Kota',
                                        field: 'city'    
                                    },
                                    {
                                        title: 'Tanggal Berangkat',
                                        field: 'departure_date'    
                                    }
                                ]}
                                responsive
                            /> : <></>}
                            </Tab.Pane>
                            <Tab.Pane eventKey="prospekumroh">
                                <YogiTabler
                                    column={colReminder({
                                        onEditFunction: (data) => {
                                            console.log('DATA', data)
                                            setReminderId(data?.id)
                                            setReminderName(data?.props?.reminder_title)
                                            setJamaahName(data?.props?.reminder_jamaah_name)
                                            setJamaahWaNumber(data?.props?.reminder_wa_number)
                                            setReminderContent(data?.props?.reminder_content)
                                            setTipeProspek(data?.props?.reminder_type)
                                            setSentTo(data?.props?.reminder_send_to)
                                            setSendAt(new Date(data?.props?.reminder_send_at));
                                            setShow(true)
                                        }
                                    })}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'Anda belum memiliki data Reminder Leads/Prospek',
                                        subheading: "Klik tombol Tambah Jamaah untuk input data Reminder baru.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'Tambah Reminder',
                                            onclick: (elem) => {
                                                createNewReminder()
                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'prospekumroh'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'No WhatsApp',
                                            field: 'no_whatsapp'    
                                        },
                                        {
                                            title: 'No Paspor',
                                            field: 'no_paspor'    
                                        },
                                        {
                                            title: 'Paket',
                                            field: 'package'    
                                        },
                                        {
                                            title: 'Kota',
                                            field: 'city'    
                                        },
                                        {
                                            title: 'Tanggal Berangkat',
                                            field: 'departure_date'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="prospekhaji">
                                <YogiTabler
                                    column={colReminder({
                                        onEditFunction: (data) => {
                                            console.log('DATA', data)
                                            setReminderId(data?.id)
                                            setReminderName(data?.props?.reminder_title)
                                            setJamaahName(data?.props?.reminder_jamaah_name)
                                            setJamaahWaNumber(data?.props?.reminder_wa_number)
                                            setReminderContent(data?.props?.reminder_content)
                                            setTipeProspek(data?.props?.reminder_type)
                                            setSentTo(data?.props?.reminder_send_to)
                                            setSendAt(new Date(data?.props?.reminder_send_at));
                                            setShow(true)
                                        }
                                    })}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'Anda belum memiliki data Reminder Leads/Prospek',
                                        subheading: "Klik tombol Tambah Jamaah untuk input data Reminder baru.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'Tambah Reminder',
                                            onclick: (elem) => {
                                                createNewReminder()
                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'prospekhaji'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'No WhatsApp',
                                            field: 'no_whatsapp'    
                                        },
                                        {
                                            title: 'No Paspor',
                                            field: 'no_paspor'    
                                        },
                                        {
                                            title: 'Paket',
                                            field: 'package'    
                                        },
                                        {
                                            title: 'Kota',
                                            field: 'city'    
                                        },
                                        {
                                            title: 'Tanggal Berangkat',
                                            field: 'departure_date'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    {/* /Page Body */}
                </Tab.Container>
            </div>

            <Modal.Dialog>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className='fs-5'>{(reminderId == '') ? 'Buat' : 'Edit'} Reminder</Modal.Title>
                    </Modal.Header>
                    <Form ref={formRef} onSubmit={handleSubmitAddManual} >
                        <Modal.Body>
                            <Form.Control type='hidden' name='reminder_id' id='reminder_id' className='reminder_id' value={reminderId}/>
                            <Form.Control type='hidden' name='auth' id='auth' className='auth' value={localStorage.getItem('rgd_token')}/>
                            <Form.Control type='hidden' name='services' id='services' className='services' value={localStorage.getItem('svc_token')}/>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className='mb-3' controlId="validataionReminderName">
                                        <Form.Label htmlFor="reminder_name">Nama Pengingat</Form.Label>
                                        <Form.Control type="text" defaultValue={reminderName} name='reminder_name' id="reminder_name" aria-describedby="remindereNameHelpBlock" placeholder='Isi Nama Pengingat' required={true}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>

                                    <Form.Group className='mb-3' controlId="validataionReminderName">
                                        <Form.Label htmlFor="reminder_name">Kapan Dikirim?</Form.Label>
                                        <DateRangePicker
                                            initialSettings={{
                                                timePicker: true,
                                                timePicker24Hour: true,
                                                singleDatePicker: true,
                                                showDropdowns: false,
                                                startDate: sendAt,
                                                locale: {
                                                    format: 'YYYY-MM-DD HH:mm',
                                                },
                                            }}
                                            onApply={(event, picker) => {
                                                setSendAt(new Date(picker.startDate));
                                            }}
                                        >
                                        <Form.Control type="text" name="single-date-pick1" />
                                    </DateRangePicker>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className='mb-3' controlId="validataionReminderJamaahName">
                                        <Form.Label htmlFor="jamaah_name">Nama Jamaah</Form.Label>
                                        <Form.Control type="text" defaultValue={jamaahName} name='jamaah_name' id="jamaah_name" aria-describedby="remindereJamaahNameHelpBlock" placeholder='Contoh: Bagas' required={true}/>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className='mb-3' controlId="validataionReminderJamaahWaNumber">
                                        <Form.Label htmlFor="jamaah_wa_number">No WA Jamaah</Form.Label>
                                        <Form.Control type="text" defaultValue={jamaahWaNumber} name='jamaah_wa_number' id="jamaah_wa_number" aria-describedby="remindereJamaahWaNumberHelpBlock" placeholder='628777xxxxxx' required={true}/>
                                    </Form.Group>
                                </div>
                            </div>
                            <Form.Group className='mb-3' controlId="validataionReminderContent">
                                <Form.Label htmlFor="reminder_content">Isi Pesan</Form.Label>
                                <div className='area-editor-toolbar'>
                                    <div className='toolbar'>
                                        <div data-tool="bold" onClick={(e) => {
                                            ClickToolbar(e)
                                        }} className="item">B</div>
                                        <div data-tool="italic" onClick={(e) => {
                                            ClickToolbar(e)
                                        }} className="item">I</div>
                                        <div data-tool="striketrhough" onClick={(e) => {
                                            ClickToolbar(e)
                                        }} className="item">S</div>
                                    </div>
                                    <div className='frame-textarea-counter'>
                                        <Form.Control as={'textarea'} rows={3} maxLength={maxCharLen} onKeyUp={(e) => {
                                            CountingCharacter(e)
                                        }} name='reminder_content' className='input-message' placeholder='Fill in your WhatsApp message here ...' required={true} defaultValue={reminderContent}></Form.Control>
                                        <span className="textarea-char-counter">{currentCharLen}/{maxCharLen}</span>
                                    </div>
                                </div>
                            </Form.Group>
                            <Form.Group className='mb-3' controlId="validataionReminderAttachment">
                                <Form.Label htmlFor="reminder_attachment">Attachment / Lampiran</Form.Label>
                                <Form.Control type="file" onChange={(e) => cek_size(e)} accept='image/png, image/jpeg, video/mp4, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' name='reminder_attachment' id='reminder_attachment' className='reminder_attachment'/>
                            </Form.Group>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Form.Group className='mb-3' controlId="validataionReminderType">
                                        <Form.Label htmlFor="reminder_type">Tipe Prospek</Form.Label>
                                        <Form.Select role='button' name='reminder_type'>
                                            <option value={''}>-- Pilih Tipe --</option>
                                            <option selected={(tipeProspek != '') ? (tipeProspek == 'umroh') ? true: false : ''} value={'umroh'}>Umroh</option>
                                            <option selected={(tipeProspek != '') ? (tipeProspek == 'hajj') ? true: false : ''} value={'hajj'}>Haji</option>
                                        </Form.Select>
                                    </Form.Group>
                                </div>
                                <div className='col-md-6'>
                                    <Form.Group className='mb-3' controlId="validataionReminderName">
                                        <Form.Label htmlFor="reminder_name">Dikirim Ke?</Form.Label>
                                        <div className='d-flex gap-3'>
                                            <Form.Check
                                                label="Diri Sendiri"
                                                name="reminder_sent_to"
                                                type={'radio'}
                                                value={'myself'}
                                                checked={(sentTo == 'myself') ? true : false}
                                                onClick={(e) => {
                                                    setSentTo(e.target.value);
                                                }}
                                                id={'reminder_sent_to_self'}
                                                role='button'
                                            />
                                            <Form.Check
                                                label="Jamaah"
                                                name="reminder_sent_to"
                                                type={'radio'}
                                                value={'jamaah'}
                                                onClick={(e) => {
                                                    setSentTo(e.target.value);
                                                }}
                                                checked={(sentTo == 'jamaah') ? true : false}
                                                id={'reminder_sent_to_jamaah'}
                                                role='button'
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className='fs-7' variant="primary" type='submit' name='create_new_reminder' value={1}>
                                {(reminderId == '') ? 'Buat' : 'Simpan'} Reminder
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Modal.Dialog>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(DashboardIndex);