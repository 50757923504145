import DashboardIndex from "../views/Dashboard";
import JamaahUmroh from "../views/Jamaah/Umroh/index";
import JamaahEditor from "../views/Jamaah/Umroh/editor";

import JamaahHajiEditor from "../views/Jamaah/Haji/editor";

import JamaahHaji from "../views/Jamaah/Haji";
import Kalkulasi from "../views/Kalkulasi";
import Jadwal from "../views/Jadwal";
import Kontak from "../views/Kontak";
import WhatsApp from "../views/WhatsApp";
import Accounts from "../views/Accounts";

import Error404 from "../views/Authentication/Error404/Error404";
import Error503 from "../views/Authentication/Error503/Error503";

import LoginClassic from "../views/Authentication/LogIn/LoginClassic/index";
import { exact } from "prop-types";

export const routes = [

    // { path: '/', exact: true, component: Dashboard },
    { path: 'dashboard', exact: true, component: DashboardIndex },
    { path: 'jamaah-umroh', exact: true, component: JamaahUmroh },
    { path: 'jamaah-haji', exact: true, component: JamaahHaji },
    { path: 'create-jamaah-umroh', exact: true, component: JamaahEditor },
    { path: 'edit-jamaah-umroh', exact: true, component: JamaahEditor },

    { path: 'create-jamaah-haji', exact: true, component: JamaahHajiEditor },
    { path: 'edit-jamaah-haji', exact: true, component: JamaahHajiEditor },

    { path: 'kalkulasi', exact: true, component: Kalkulasi },
    { path: 'jadwal', exact: true, component: Jadwal },
    { path: 'contacts', exact: true, component: Kontak },
    { path: 'whatsapp', exact: true, component: WhatsApp },
    { path: 'account', exact: true, component: Accounts },
    { path: 'error-404', exact: true, component: Error404 },
]

export const authRoutes = [
    { path: '/login', exact: true, component: LoginClassic },
    { path: '/error-503', exact: true, component: Error503 },
]