import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { updateTopNavTitle } from '../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';

import { colHaji } from './DraftTableData';
import YogiTabler from '../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const WhatsAppIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [waQRCode, setWaQRCode] = useState('https://semutganteng.fra1.cdn.digitaloceanspaces.com/UniPlay/qrcode_dummy.png')
    const [isScanned, setIsScanned] = useState(false);
    const [isGenerated, setIsGenerated] = useState(false);
    const [isConnected, setIsConnected] = useState(false);

    const [connectionId, setConnectionId] = useState('')
    const [connectedBtnCaption, SetConnectedBtnCaption] = useState('Generate QR')

    const [intervalCheckingIsConnected, setIntervalCheckingIsConnected] = useState(null);
    const [connectionInfo, setConnectionInfo] = useState(null);
    
    const intervalRef = useRef(null);

    const playerRef = useRef(null);

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'WhatsApp',
            actions: {}
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkIsConnected = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'whatsapp_check_instance', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    generated: true,
                    connection_id: connectionId,
                    auth: localStorage.getItem('rgd_token')
                })
            });

            const data = await response.json();

            if (data?.status) {
                if (data?.connection_info?.is_connected) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    // connected

                    Swal.fire({
                        title: "Yeay, Berhasil!",
                        text: "Nomor WhatsApp Berhasil Terhubung",
                        icon: "success"
                    }).then(() => {
                        setIsGenerated(false)
                        setIsScanned(false)
                        setIsConnected(true)
                        SetConnectedBtnCaption('Disconnect')
                    });
                } else if (data?.connection_info?.is_scanned) {
                    // scanned
                    setIsScanned(true)
                    SetConnectedBtnCaption('Wait...')
                }
                setConnectionInfo(data.connection_info);
            } else {
                // handle error
            }
        } catch (error) {
            console.error('Error checking connection:', error);
        }
    };

    const requestDisconnected = async() => {
        const reqBody = {
            auth: localStorage.getItem('rgd_token'),
            connection_id: connectionId
        }

        Swal.fire({
            title: "Konfirmasi",
            text: "Yakin ingin disconnect nomor ini? setelah disconnect maka notifikasi via WhatsApp ini akan terhenti",
            showCancelButton: true,
            confirmButtonText: "Yakin",
            cancelButtonText: "Batal",
            icon: "question"
        }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {

                const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'whatsapp_disconnect', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(reqBody) // Sending current page to fetch data accordingly
                });
        
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
        
                const result = await response.json();
            }
        });

        
    }

    const requestQrCode = async(e) => {

        setIsScanned(false)
        setIsGenerated(false)

        const reqBody = {
            auth: localStorage.getItem('rgd_token')
        }

        const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'whatsapp_init', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(reqBody) // Sending current page to fetch data accordingly
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result = await response.json();

        if(result?.status){
            setWaQRCode(result?.barcode_qr)
            setIsGenerated(true)
            setConnectionId(result?.connection_id)

            //check is connected or not
            if (intervalRef.current === null) {
                intervalRef.current = setInterval(() => {
                    checkIsConnected();
                }, 3000);
            }
        }

        console.log('result', result)
    }

    useEffect(() => {
        // Cleanup interval on component unmount
        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return (
        <>
            <div className='p-3 pt-0 mt-3'>
                <div className="area-connection-whatsapp-state">
                    <div className={classNames({'qr-area' : true}, {'before-generated': !isGenerated || isScanned}, {'after-generated' : isGenerated && !isScanned})}>
                        <img className="target_qr_code_wa" src={waQRCode} alt="qrcode"/>
                        <a onClick={(e) => {
                            if(!isConnected){
                                requestQrCode(e)
                            }else{
                                requestDisconnected(e)
                            }
                        }} href="#" className={classNames({'qr-area-generate-qr' : true}, {'connectedbtn' : !isConnected}, {'disconnectedbtn' : isConnected})} >{connectedBtnCaption}</a>
                        <div className={classNames({'how-to-connect' : true}, {'show' : isGenerated})}>
                            <h3>How To Connect?</h3>
                            <table style={{marginTop: '-10px'}}>
                                <tbody>
                                    <tr>
                                        <td className="less-pad">1.&nbsp;&nbsp;Open <span className="grey lighten-3 black-text" style={{padding: '2px 5px', margin: '0 3px', borderRadius: '5px'}}>WhatsApp</span> on your phone</td>
                                    </tr>
                                    <tr>
                                        <td className="less-pad">2.&nbsp;&nbsp;Click <span className="grey lighten-3 black-text" style={{padding: '2px 5px', margin: '0 3px', borderRadius: '5px'}}>3-dots</span> menu on the top right corner</td>
                                    </tr>
                                    <tr>
                                        <td className="less-pad">3.&nbsp;&nbsp;Tap on <span className="grey lighten-3 black-text" style={{padding: '2px 5px', margin: '0 3px', borderRadius: '5px'}}>"Linked Devices"</span></td>
                                    </tr>
                                    <tr>
                                        <td className="less-pad">4.&nbsp;&nbsp;After that, <span className="grey lighten-3 black-text" style={{padding: '2px 5px', margin: '0 3px', borderRadius: '5px'}}>Scan QR Code</span> below</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="connection-info-wa">
                        <div className="cia-heading-wa">
                            <img src="https://semutganteng.fra1.cdn.digitaloceanspaces.com/UniPlay/33_wa.svg" alt="Logo WhatsApp"/>
                            <h3>WhatsApp Notification</h3>
                            {(!isConnected) ? <span className="cia-badge badge-danger">Disconnected</span> : <></>}
                            {(isConnected) ?  <span className="cia-badge badge-success">Connected</span> : <></>}
                        </div>

                        <div className="cia-info-ondisconnected">
                            <p>Nomor WhatsApp ini akan digunakan sebagai nomor pengirim notifikasi reminder kepada jamaah<br/>Sehingga dapat meningkatkan kualitas &amp; kepercayaan terhadap branding anda.</p>
                            {(!isConnected) ? <p>Klik tombol <b>“Generate QR”</b> untuk memulai.</p> : <></>}
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(WhatsAppIndex);