import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button, Card, Table } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { updateTopNavTitle } from '../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import debounce from 'lodash.debounce';
import Select from 'react-select';

import { colHaji } from './DraftTableData';
import YogiTabler from '../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
import { Trash, BrandWhatsapp } from 'tabler-icons-react';
const SMILEJson = require('./iconjson/smile.json');

const KalkulasiIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const playerRef = useRef(null);
    const formRef = useRef();

    const [aiwIDPackage, setAiwIDPackage] = useState('')
    const [aiwJadwalList, setAiwJadwalList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDiskonInput, setShowDiskonInput] = useState(false)
    const [show, setShow] = useState(false)

    const [aiwPackageSelected, setAiwPackageSelected] = useState({})

    const [calculateResponse, setCalculateResponse] = useState({})

    const [jamaahList, setJamaahList] = useState([{ id: Date.now() }]);

    const addJamaah = () => {
        setJamaahList([...jamaahList, { id: Date.now() }]);
    };

    const removeJamaah = (id) => {
        setJamaahList(jamaahList.filter(jamaah => jamaah.id !== id));
    };

    const loadAiwJadwal = async (inputValue) => {

        setLoading(true);
        try {
          // Mengirim permintaan POST dengan fetch
          const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'jadwal/search', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query: inputValue, auth: localStorage.getItem('rgd_token'), services: localStorage.getItem('svc_token') })
          });
          
          // Memeriksa apakah respons berhasil
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();

          if(data?.status){
            setAiwJadwalList(data?.options)
          }else{
            setAiwJadwalList([])
          }

          setLoading(false);
          return data?.options;
        } catch (error) {
          console.error('Error fetching options:', error);
          setLoading(false);
          return [];
        }
    };

    const debouncedFetchAIWJadwal = useCallback(debounce(loadAiwJadwal, 1000), []);

    const handleSearchTanggal =  (inputValue) => {
        if (inputValue) {
            debouncedFetchAIWJadwal(inputValue);
        } else {
          setAiwJadwalList([]);
        }
    };


    const handleSubmitAddManual =  (e) => {
        e.preventDefault();

        window.onbeforeunload = null

        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (key === 'file') {
                data[key] = value; // Append file directly
                return;
            }
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

        const authValue = apiParams?.auth;
        const servicesValue = apiParams?.services;

        if (!data['auth']) {
            data['auth'] = authValue;
        } else {
            if (!Array.isArray(data['auth'])) {
                data['auth'] = [data['auth']];
            }
            data['auth'].push(authValue);
        }

        if (!data['services']) {
            data['services'] = servicesValue;
        } else {
            if (!Array.isArray(data['services'])) {
                data['services'] = [data['services']];
            }
            data['services'].push(servicesValue);
        }

        console.log(data);

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'umroh/kalkulasi';
        const requestOptions = {
            method: 'POST',
            // Note: No need to set 'Content-Type' header for FormData
            body: formData, 
        };

        fetch(apiEndpoint, requestOptions)
        .then(async (response) => {
            var resJson = await response.json();

            if (resJson?.status) {
                setShow(true)
                setCalculateResponse(resJson)
            } else {
                Swal.fire('Analyzing Error', resJson?.message, 'error');
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Kalkulasi',
            actions: {}
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
        <Form ref={formRef} onSubmit={handleSubmitAddManual}>
            <Form.Control type='hidden' name='auth' id='auth' className='auth' value={localStorage.getItem('rgd_token')}/>
            <div className='p-3 pt-0 mt-3'>
                <div className='row'>
                    <div className='col-md-12'>
                        <Form.Group className='mb-3' controlId="validataionReminderJamaahWaNumber">
                            <Form.Label htmlFor="jamaah_wa_number">Keberangkatan</Form.Label>
                            <Form.Control type='hidden' value={aiwIDPackage} name='jamaah_package_aiw' className='jamaah_package_aiw' id='jamaah_package_aiw'/> 
                            <Select
                                isLoading={loading}
                                loadAiwJadwal={loadAiwJadwal}
                                options={aiwJadwalList}
                                onInputChange={handleSearchTanggal}
                                onChange={(selected) => {
                                    setAiwIDPackage(selected?.value)
                                    // console.log('selected', )
                                    setAiwPackageSelected(selected)
                                    setJamaahList([{ id: Date.now() }])
                                }}
                                cacheOptions
                                defaultOptions
                                placeholder="Search..."
                            />
                        </Form.Group>
                    </div>
                </div>

                {aiwPackageSelected?.biaya ? <>
                    <div className='row'>
                    <div className='col-md-12'>
                        <Card>
                            <Card.Header>Data Jamaah</Card.Header>
                            <Card.Body className='parent-clone-jamaah d-flex gap-3 flex-column'>
                                {jamaahList.map(jamaah => (
                                    <div className='row child-clone-jamaab'>
                                        <div className='col-md-3'>
                                            <Form.Label htmlFor="jamaah_tipe">Tipe Jamaah</Form.Label>
                                            <Form.Select role='button' name='jamaah_tipe[]'>
                                                <option value={''}>-- Pilih Tipe --</option>
                                                <option value={'1'}>DEWASA / ANAK → with bed</option>
                                                <option value={'2'}>ANAK → without bed</option>
                                                <option value={'3'}>BAYI → without bed</option>
                                            </Form.Select>
                                        </div>
                                        <div className='col-md-1'>
                                            <Form.Label htmlFor="jamaah_jumlah">Jumlah</Form.Label>
                                            <Form.Control type="text" name='jamaah_jumlah[]' id="jamaah_jumlah" aria-describedby="jamaah_jumlahHelpBlock" placeholder='0'/>
                                        </div>
                                        <div className='col-md-4'>
                                            <Form.Label htmlFor="jamaah_perlengkapan">Perlengkapan</Form.Label>
                                            <Form.Select role='button' name='jamaah_perlengkapan[]'>
                                                <option value={''}>-- Pilih Perlengkapan --</option>
                                                {aiwPackageSelected?.biaya ? (
                                                Object.keys(aiwPackageSelected?.biaya?.PAKET).map((key) => (
                                                    <option value={aiwPackageSelected?.biaya?.PAKET[key]?.PERLENGKAPAN}>Ambil Perlengkapan → {aiwPackageSelected?.biaya?.PAKET[key]?.PERLENGKAPAN}</option>
                                                )) ) : (<></>)}
                                            </Form.Select>
                                        </div>
                                        <div className='col-md-3'>
                                            <Form.Label htmlFor="jamaah_paket">Paket</Form.Label>
                                            <Form.Select role='button' name='jamaah_paket[]'>
                                                <option value={''}>-- Pilih Paket --</option>
                                                {aiwPackageSelected?.biaya ? (
                                                Object.keys(aiwPackageSelected?.biaya?.PAKET).map((key) => (
                                                    <optgroup label={key}>
                                                        {aiwPackageSelected?.biaya?.PAKET[key]?.PACKAGE.map((valuepackage, index) => (
                                                            <option value={key+'|'+valuepackage?.name+'|'+valuepackage?.price}>{valuepackage?.name} → {valuepackage?.price}</option>
                                                        ))}
                                                    </optgroup>
                                                )) ) : (<></>)}
                                            </Form.Select>
                                        </div>
                                        <div className='col-md-1 pt-5'>
                                            <Button className='btn btn-light btn-sm h-100'  onClick={(e) => {
                                                removeJamaah(jamaah.id)
                                            }}>
                                                <Trash size={19}/>
                                            </Button>
                                        </div>
                                    </div>
                                    ))}
                            </Card.Body>
                            <Card.Footer>
                                <Button onClick={(e) => {
                                    addJamaah()
                                }} className='btn btn-primary btn-sm'>
                                    <span>Tambah Jamaah</span>
                                </Button>
                            </Card.Footer>
                        </Card>
                    </div>
                </div>
                </> : <></>}
              

                <div className='row'>
                    <div className='col-md-6'>
                       <div className='d-flex gap-1 w-100 flex-column'>
                            <Form.Group className='mb-3' controlId="diskon">
                                <Form.Label htmlFor="diskon">Diskon</Form.Label>
                                <Form.Select onChange={(e) => {
                                    if(e.target.value != ''){
                                        setShowDiskonInput(true)
                                    }else{
                                        setShowDiskonInput(false)
                                    }
                                }} role='button' name='diskon'>
                                    <option value={''}>TIDAK ADA DISKON</option>
                                    <option value={'sebagian'}>SEBAGIAN JAMAAH</option>
                                    <option value={'setiap'}>SETIAP JAMAAH</option>
                                </Form.Select>
                            </Form.Group>

                            {showDiskonInput ? <>
                                <Form.Group className='mb-3' controlId="nilaidiskon">
                                    <Form.Label htmlFor="nilaidiskon">Nilai Diskon</Form.Label>
                                    <Form.Control type="text" name='nilaidiskon' id="nilaidiskon" aria-describedby="nilaidiskonHelpBlock" placeholder='0'/>
                                </Form.Group>
                            </> : <></>}
                       </div>
                    </div>
                    <div className='col-md-3'>
                        <Form.Label htmlFor="tiketfit">Tiket FIT <small>(Jumlah Jamaah)</small></Form.Label>
                        <Form.Control type="text" name='tiketfit' id="tiketfit" aria-describedby="tiketfitHelpBlock" placeholder='0'/>
                    </div>
                    <div className='col-md-3'>
                        <Form.Label htmlFor="tiketfitperjamaah">Tiket FIT <small>(/Jamaah Rupiah)</small></Form.Label>
                        <Form.Control type="text" name='tiketfitperjamaah' id="tiketfitperjamaah" aria-describedby="tiketfitperjamaahHelpBlock" placeholder='0'/>
                    </div>
                </div>

                <div className='mt-3 d-flex gap-2'>
                    <Button className='fs-7' onClick={(e) => {
                        history.push("/jamaah-umroh")
                    }} variant="light" type='button'>
                        Kembali
                    </Button>
                    <Button className='fs-7' variant="primary" type='submit' name='calculatenow' value={1}>
                        Hitung Total Biaya
                    </Button>
                </div>
            </div>
        </Form>

        <Modal.Dialog>
            <Modal size='lg' show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='fs-5'>HASIL PERHITUNGAN</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table bordered hover className='result_perhitungan_table'>
                        <tr>
                            <td>BERANGKAT</td>
                            <td>:</td>
                            <td>{calculateResponse?.berangkat}</td>
                        </tr>
                        <tr>
                            <td>PULANG</td>
                            <td>:</td>
                            <td>{calculateResponse?.pulang}</td>
                        </tr>
                        <tr>
                            <td>MASKAPAI</td>
                            <td>:</td>
                            <td>{calculateResponse?.maskapai}</td>
                        </tr>
                        <tr>
                            <td colSpan={3}><b className='p-0'>DETAIL PAKET</b></td>
                        </tr>
                        {calculateResponse?.paket?.map((itemPaket, index) => (
                            <>
                            
                            <tr>
                                <td>PAKET</td>
                                <td>:</td>
                                <td>{itemPaket?.name}</td>
                            </tr>
                            {calculateResponse?.hotel?.map((itemHotel, index) => (
                                <>
                                <tr>
                                    <td>{itemHotel?.location}</td>
                                    <td>:</td>
                                    <td>{itemHotel?.name}</td>
                                </tr>
                                </>
                            ))}
                            <tr>
                                <td>{itemPaket?.tipe_jamaah}</td>
                                <td>:</td>
                                <td>x{itemPaket?.jumlah_jamaaah} <span className='hargapaket'>{itemPaket?.harga}</span></td>
                            </tr>
                            <tr>
                                <td>PERLENGKAPAN</td>
                                <td>:</td>
                                <td>x{itemPaket?.jumlah_jamaaah} <span className='hargapaket'>{itemPaket?.perlengkapan}</span></td>
                            </tr>
                            <tr>
                                <td>PCR & KARANTINA</td>
                                <td>:</td>
                                <td>x{itemPaket?.jumlah_jamaaah} <span className='hargapaket'>{itemPaket?.pcr_karantina}</span></td>
                            </tr>
                            </>
                        ))}
                        <tr>
                            <td colSpan={3}><b className='p-0'>BIAYA LAINNYA</b></td>
                        </tr>
                        <tr>
                            <td>TIKET FIT</td>
                            <td>:</td>
                            <td>x{calculateResponse?.tiketfit?.jamaah} <span className='hargapaket'>{calculateResponse?.tiketfit?.price}</span></td>
                        </tr>
                        <tr>
                            <td>DISKON</td>
                            <td>:</td>
                            <td>x{calculateResponse?.diskon?.jamaah} <span className='hargapaket'>{calculateResponse?.diskon?.price}</span></td>
                        </tr>
                        <tr>
                            <td><b className='p-0'>GRAND TOTAL</b></td>
                            <td>:</td>
                            <td><b className='p-0'>{calculateResponse?.grantotal}</b></td>
                        </tr>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='success' onClick={(e) => {
                        // console.log('CR', calculateResponse)
                        window.open(calculateResponse?.link_share, '_blank');
                    }} className='btn btn-success d-flex gap-2'>
                        <BrandWhatsapp/>
                        <span>Share via WhatsApp</span>
                    </Button>
                </Modal.Footer>
            </Modal>
        </Modal.Dialog>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(KalkulasiIndex);