import { Archive, Copy, Edit, MoreVertical, Trash, Trash2, Eye } from 'react-feather';
import { Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import HkBadge from '../../../components/@hk-badge/@hk-badge';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import Swal from 'sweetalert2';
import { Pencil } from 'tabler-icons-react';

//Custom Action Container
const actionFormater = (data, callbackRefreshTable, extendFunction) => {

    const actions = data?.actions;

    const handleDuplicateBroadcast = (elem, id) => {
        Swal.fire({
            title: 'Confirmation',
            text: "Are you duplicate this broadcast?",
            icon: 'question',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, duplicate it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {
                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'broadcast/duplicate';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
            'services': localStorage.getItem('svc_token'),
                    'duplicate': id
                }
            
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }
            
            }
        })
    }

    const handleEditingPopup = (elem, data) => {
        if(extendFunction?.onEditFunction){
            extendFunction?.onEditFunction(data)
        }
    }

    const handleDelete = (elem, id) => {
        // console.log('ID Delete', id)

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-light ms-3'
            }
        }).then( async(result) => {
            if (result.isConfirmed) {

                const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'reminder/purge';
                const apiParams = {
                    'auth': localStorage.getItem('rgd_token'),
                    'services': localStorage.getItem('svc_token'),
                    'delete': id
                }
               
                const bodySend = JSON.stringify(apiParams);
                const response = await fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json'
                    },
                    body: bodySend // Sending current page to fetch data accordingly
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            
                const result = await response.json();

                if(result?.status){
                    callbackRefreshTable(result)
                    Swal.fire(
                        result?.headline,
                        result?.message,
                        'success'
                    )
                }else{
                    Swal.fire(
                        'Failed!',
                        result?.message,
                        'danger'
                    )
                }

            }
        })
    }

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex">
                {(actions?.use_continue_editing) ? <Button as={Link} to={data?.reportBroadcastLink} className="btn btn-outline-light btn-sm" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast" style={{lineHeight:'2'}}>
                    Continue Editing
                </Button> : <></>}

                {(actions?.use_edit) ? <Button variant="flush-dark" data-id={data?.id} onClick={(elem) => handleEditingPopup(elem, data)} className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Edit">
                    <span className="icon">
                        <span className="feather-icon">
                            <Pencil />
                        </span>
                    </span>
                </Button> : <></>}

                {(actions?.use_view_button) ? <Button variant="flush-dark" as={Link} to={data?.reportBroadcastLink} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="View Report Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <Eye />
                        </span>
                    </span>
                </Button> : <></>}

                {(actions?.use_duplicate_btn) ? <Button variant="flush-dark" data-id={data?.id} onClick={(elem) => handleDuplicateBroadcast(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Duplicate Broadcast">
                    <span className="icon">
                        <span className="feather-icon">
                            <Copy />
                        </span>
                    </span>
                </Button> : <></>}

              
                
                {(actions?.use_delete_btn) ? <Button variant="flush-dark" data-id={data?.id} onClick={(elem) => handleDelete(elem, data?.id)} className="btn-icon btn-rounded flush-soft-hover del-button" data-bs-toggle="tooltip" data-placement="top" data-bs-original-title="Delete">
                    <span className="icon">
                        <span className="feather-icon">
                            <Trash />
                        </span>
                    </span>
                </Button> : <></>}
                
               
            </div>
        </div>
    )
}


export const colReminder = (otherActionFunction) =>  {
    return [
        {
            accessor: "name",
            title: "Name",
            elipsis: true,
            sort: true,
            cellFormatter: (data) => {
                
                try {
                    var dataJson = JSON.parse(data)
    
                    if((typeof dataJson === 'object' && dataJson !== null)){
                        return (<Link className="table-text-link" to={'/reminder-view/'+ dataJson?.id}><span className="text-truncate text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{dataJson?.text}</span></Link>)
                    }else{
                        return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                    }
                } catch (e) {
                    return (<span className="d-block text-high-em" style={{display:'inline-block',maxWidth:'360px'}}>{data}</span>)
                }
    
               
            },
        },
        {
            accessor: "name_jamaah",
            title: "Nama Jamaah",
            elipsis: false,
            sort: true,
        },
        {
            accessor: "wa_jamaah",
            title: "No WhatsApp",
            elipsis: false,
            sort: true,
        },
        {
            accessor: "send_at",
            title: "Tanggal Reminder",
            elipsis: false,
            sort: true,
        },
        {
            accessor: "type",
            title: "Tipe",
            elipsis: false,
            sort: true,
        },
        {
            accessor: "send_to",
            title: "Kirim Ke",
            elipsis: false,
            sort: true,
        },
        {
            accessor: "actions",
            title: "",
            cellFormatter: (a, b) => {
                // console.log('CFORMATTER A', a)
                // console.log('CFORMATTER B', b)
                // actionFormater(data)

                return actionFormater(a, b, otherActionFunction)
            },
        },
    ]
};