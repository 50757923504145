import React, { useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
//Images
import { AlignLeft, Bell, Calendar, CheckSquare, Clock, CreditCard, Inbox, Plus, Search, Settings, Tag } from 'react-feather';
import { IconChevronLeft } from '@tabler/icons-react'
import { Button, Container, Dropdown, Form, InputGroup, Nav, Navbar, Breadcrumb } from 'react-bootstrap';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import CustomInput from './CustomInput';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import HkBadge from '../../components/@hk-badge/@hk-badge';
import { useRouteMatch } from 'react-router-dom';
import { useEffect } from 'react';
import { IconSend2, IconCode, IconUserSearch } from '@tabler/icons-react';


const TopNav = ({ navCollapsed, toggleCollapsedNav, title, props }) => {

    const [showDropdown, setShowDropdown] = useState(false);
    const [searchValue, setSearchValue] = useState("")

    const [headInfo, setHeadInfo] = useState([])

    const [initialChar, setInitialChar] = useState('--')

    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const head_info = localStorage.getItem('head_info')
        try{
            setHeadInfo(JSON.parse(head_info))
        }catch(erx){}

        try{
            setInitialChar(head_info?.name.split(" ")?.map(word => word[0]).join("")?.toUpperCase())
        }catch(erx){}
    },[])

    //accounts-settings
    // automations

    const CloseSearchInput = () => {
        setSearchValue("");
        setShowDropdown(false);
    }

    const pageVariants = {
        initial: {
            opacity: 0,
            y: 10
        },
        open: {
            opacity: 1,
            y: 0
        },
        close: {
            opacity: 0,
            y: 10
        }
    };



    return (
        <Navbar expand="xl" className="hk-navbar navbar-light fixed-top" >
            <Container fluid>
                {/* Start Nav */}
                <div className="nav-start-wrap">
                    <Button variant="flush-dark" onClick={() => toggleCollapsedNav(!navCollapsed)} className="btn-icon btn-rounded flush-soft-hover navbar-toggle d-xl-none">
                        <span className="icon">
                            <span className="feather-icon"><AlignLeft /></span>
                        </span>
                    </Button>
                    <div className='d-flex gap-3 align-contents-center align-items-center'>

                        {(title?.breadcrumb) ? <>
                        <Breadcrumb className='breadcrumb-as-headers ms-2'>
                            {title?.breadcrumb.map((value, key) => (
                                 <Breadcrumb.Item onClick={value?.onClick} active={value?.is_active} className={value?.className}>{value?.title}</Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                        </> : <><h5 className='mb-0 ms-2'>{title?.title}</h5></>}
                        {(title?.actions) ? <a className={title?.actions?.classlists} onClick={title?.actions?.onclick}>
                            {title?.actions?.icons} {title?.actions?.title}
                        </a> : <></>}
                    </div>
                    
                </div>
                {/* /Start Nav */}
                {/* End Nav */}
                <div className="nav-end-wrap">
                    {(title?.custom_element_right) ? title?.custom_element_right : <></>}
                    {(title?.action_right) ? <a className={title?.action_right?.classlists} onClick={title?.action_right?.onclick}>
                            {title?.action_right?.icons} {title?.action_right?.title}
                    </a> : <></>}
                    <Nav className="navbar-nav flex-row">
                        <Nav.Item>
                            <Dropdown className="ps-2">
                                <Dropdown.Toggle as={Link} to="#" className="no-caret">
                                    <div className="avatar avatar-primary avatar-rounded avatar-xs">
                                        <span className="initial-wrap">{initialChar}</span>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end">
                                    <div className="p-2">
                                        <div className="media">
                                            <div className="media-head me-2">
                                                <div className="avatar avatar-primary avatar-sm avatar-rounded">
                                                    <span className="initial-wrap">{initialChar}</span>
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <span className="d-block fw-medium text-dark">{headInfo?.name}</span>
                                                <div className="fs-7">{headInfo?.email}</div>
                                                <Link to="#" onClick={(el) => {
                                                    localStorage.removeItem('rgd_token');
                                                    localStorage.removeItem('svc_token');
                                                    localStorage.removeItem('head_info');
                                    
                                                    window.location.href = '/auth/login'
                                                }} className="d-block fs-8 link-secondary">
                                                    <u>Sign Out</u>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <Dropdown.Divider as="div" />
                                    <h6 className="dropdown-header">Manage Account</h6>
                                    <Dropdown.Item>
                                        <span className="dropdown-icon feather-icon">
                                            <IconSend2 />
                                        </span>
                                        <span>Email Senders</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <span className="dropdown-icon feather-icon">
                                            <IconCode />
                                        </span>
                                        <span>Integrations</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        <span className="dropdown-icon feather-icon">
                                            <IconUserSearch />
                                        </span>
                                        <span>Account Settings</span>
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav.Item>
                    </Nav>
                </div>
                {/* /End Nav */}
            </Container>
        </Navbar>
    )
}

const mapStateToProps = ({ theme, headerReducer }) => {
    const { navCollapsed } = theme;
    const { title } = headerReducer;
    return { navCollapsed, title }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(TopNav);
