import React from 'react';
import { connect } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { Link } from 'react-router-dom';
//Images
import logo from '../../assets/dist/img/brand-sm.svg';
import jampackImg from '../../assets/dist/img/Jampack.svg';
import { ArrowBarToLeft } from 'tabler-icons-react';
import { Button } from 'react-bootstrap';

const SidebarHeader = ({ navCollapsed, toggleCollapsedNav }) => {

    const toggleSidebar = () => {
        toggleCollapsedNav(!navCollapsed);
        document.getElementById('tggl-btn').blur();
    }
    return (
        <div className="menu-header justify-content-center">
                <Link className="navbar-brand" to="/">
                    <img className="brand-img img-fluid" src="https://alhijazindonesia.com/wp-content/uploads/2023/09/alhijazindowisata.png" style={{width: '130px', marginTop: '15px'}} alt="brand" />
                </Link>
        </div>
    )
}

const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(SidebarHeader);
