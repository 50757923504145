import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useParams, useHistory,useLocation } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import moment from 'moment-hijri';
import debounce from 'lodash.debounce';

import { colHaji } from './DraftTableData';
import YogiTabler from '../../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
import Select from 'react-select';
const SMILEJson = require('./iconjson/smile.json');


const JamaahHajiEditor = ({ navCollapsed, toggleCollapsedNav }) => {

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const idParams = query.get('id');

    const dispatch = useDispatch();
    const history = useHistory();
    const [listYearHijri, setListYearHijri] = useState([])

    const [optionsKotaKelahiran, setOptionsKotaKelahiran] = useState([]);
    const [loadingKotaKelahiran, setLoadingKotaKelahiran] = useState(false);

    const [optionsKotaAsal, setOptionsKotaAsal] = useState([]);
    const [loadingKotaAsal, setLoadingKotaAsal] = useState(false);

    const [optionsKecamatanAsal, setOptionsKecamatanAsal] = useState([]);
    const [loadingKecamatanAsal, setLoadingKecamatanAsal] = useState(false);

    const [optionsKelurahanAsal, setOptionsKelurahanAsal] = useState([]);
    const [loadingKelurahanAsal, setLoadingKelurahanAsal] = useState(false);

    const [jamaahId, setJamaahId] = useState('')
    const [jenisHaji, setJenisHaji] = useState('')

    const [dateBorn, setDateBorn] = useState(new Date('1990-01-01'))
    const [kotaKelahiranID, setKotaKelahiranID] = useState('')
    const [kotaKelahiranDefaultValue, setKotaKelahiranDefaultValue] = useState([])

    const [jenkel, setJenkel] = useState('pria')

    const [kotaAsalId, setKotaAsalId] = useState('')
    const [kotaAsalDefaultValue, setKotaAsalDefaultValue] = useState([])

    const [kecamatanAsalId, setKecamatanAsalId] = useState('')
    const [kecamatanAsalDefaultValue, setKecamatanAsalDefaultValue] = useState([])

    const [kelurahanAsalId, setKelurahanAsalId] = useState('')
    const [kelurahanAsalDefaultValue, setKelurahanAsalDefaultValue] = useState([])

    //jamaahFirstName
    const [jamaahFirstName, setJamaahFirstName] = useState('')
    const [jamaahMiddleName, setJamaahMiddleName] = useState('')
    const [jamaahLastName, setJamaahLastName] = useState('')

    const [jamaahAlhijazNo, setJamaahAlhijazNo] = useState('')
    const [jamaahNoWhatsapp, setJamaahNoWhatsapp] = useState('')
    const [jamaahStatusNikah, setJamaahStatusNikah] = useState('')

    const formRef = useRef();

    const loadKotaKelahiran = async (inputValue) => {

        setLoadingKotaKelahiran(true);
        try {
          // Mengirim permintaan POST dengan fetch
          const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'district/search', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                query: inputValue, 
                auth: localStorage.getItem('rgd_token'), 
                services: localStorage.getItem('svc_token'),
                type: 'city'
            })
          });
          
          // Memeriksa apakah respons berhasil
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();

          if(data?.status){
            setOptionsKotaKelahiran(data?.options)
          }else{
            setOptionsKotaKelahiran([])
          }

          setLoadingKotaKelahiran(false);
          return data?.options;
        } catch (error) {
          console.error('Error fetching options:', error);
          setLoadingKotaKelahiran(false);
          return [];
        }
    };


    const loadKotaAsal = async (inputValue) => {

        setLoadingKotaAsal(true);
        try {
          // Mengirim permintaan POST dengan fetch
          const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'district/search', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                query: inputValue, 
                auth: localStorage.getItem('rgd_token'), 
                services: localStorage.getItem('svc_token'),
                type: 'city'
            })
          });
          
          // Memeriksa apakah respons berhasil
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();

          if(data?.status){
            setOptionsKotaAsal(data?.options)
          }else{
            setOptionsKotaAsal([])
          }

          setLoadingKotaAsal(false);
          return data?.options;
        } catch (error) {
          console.error('Error fetching options:', error);
          setLoadingKotaAsal(false);
          return [];
        }
    };


    const loadKecamatanAsal = async (inputValue, kotaAsalIdParams) => {

        // console.log('kotaAsalId', kotaAsalIdParams)

        setLoadingKecamatanAsal(true);
        try {
          // Mengirim permintaan POST dengan fetch
          const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'district/search', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                query: inputValue, 
                city_id: kotaAsalIdParams,
                auth: localStorage.getItem('rgd_token'), 
                services: localStorage.getItem('svc_token'),
                type: 'subcity'
            })
          });
          
          // Memeriksa apakah respons berhasil
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();

          if(data?.status){
            setOptionsKecamatanAsal(data?.options)
          }else{
            setOptionsKecamatanAsal([])
          }

          setLoadingKecamatanAsal(false);
          return data?.options;
        } catch (error) {
          console.error('Error fetching options:', error);
          setLoadingKecamatanAsal(false);
          return [];
        }
    };


    const loadKelurahanAsal = async (inputValue, kecamatanAsalIdParams) => {

        setLoadingKelurahanAsal(true);
        try {
          // Mengirim permintaan POST dengan fetch
          const response = await fetch(process.env.REACT_APP_URL_VITALS_API + 'district/search', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                query: inputValue, 
                subcity_id: kecamatanAsalIdParams,
                auth: localStorage.getItem('rgd_token'), 
                services: localStorage.getItem('svc_token'),
                type: 'villages'
            })
          });
          
          // Memeriksa apakah respons berhasil
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();

          if(data?.status){
            setOptionsKelurahanAsal(data?.options)
          }else{
            setOptionsKelurahanAsal([])
          }

          setLoadingKelurahanAsal(false);
          return data?.options;
        } catch (error) {
          console.error('Error fetching options:', error);
          setLoadingKelurahanAsal(false);
          return [];
        }
    };

    const debouncedFetchKotaKelahiran = useCallback(debounce(loadKotaKelahiran, 1000), []);

    const debouncedFetchKotaAsal = useCallback(debounce(loadKotaAsal, 1000), []);
    const debouncedFetchKecamatanAsal = useCallback(debounce(loadKecamatanAsal, 1000), []);
    const debouncedFetchKelurahanAsal = useCallback(debounce(loadKelurahanAsal, 1000), []);


    const handleSearchKotaKelahiran =  (inputValue) => {
        if (inputValue) {
            debouncedFetchKotaKelahiran(inputValue);
        } else {
        //   setOptionsKotaKelahiran([]);
        }
    };


    const handleSearchKotaAsal =  (inputValue) => {
        if (inputValue) {
            debouncedFetchKotaAsal(inputValue);
        } else {
          setOptionsKotaAsal([]);
        }
    };
    const handleSearchKecamatanAsal =  (inputValue) => {
        if (inputValue) {
            debouncedFetchKecamatanAsal(inputValue, kotaAsalId);
        } else {
          setOptionsKecamatanAsal([]);
        }
    };
    const handleSearchKelurahanAsal =  (inputValue) => {
        if (inputValue) {
            debouncedFetchKelurahanAsal(inputValue, kecamatanAsalId);
        } else {
          setOptionsKelurahanAsal([]);
        }
    };

    const cek_size = (event) => {
        const elem = event.target;
        const file = elem.files[0];
        const $size = file.size;
    
        if (file) {
            const validTypes = [
                'image/png',
                'image/jpg',
                'image/jpeg',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.ms-excel',
                'application/pdf',
                'video/mp4'
            ];
        
            if ($size <= 1048576) {
                if (validTypes.includes(file.type)) {
                    if (
                        file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
                        file.type === 'application/vnd.ms-excel' ||
                        file.type === 'application/pdf' ||
                        file.type === 'video/mp4'
                    ) {
                        console.log('IS FILE');
                    } else {
                        console.log('IS PICTURE');
                    }
            
                    window.onbeforeunload = () => 'Cancel Reload';
                } else {
                console.log('INVALID FILE FORMAT');

                    Swal.fire("Format File Invalid", "Silahkan pilih file dengan format JPEG/JPG/PNG/PDF/XLS/XSLX/MP4.", "error").then(function() {
                        elem.value = null;
                    });
                }
            } else {
                console.log('IS TOO BIG');
                Swal.fire("That's too big", "Maximum file size is 1MB.", "error").then(function() {
                    elem.value = null;
                });
            }
        }
    }

    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const handleSubmitAddManual =  (e) => {
        e.preventDefault();

        window.onbeforeunload = null

        const formData = new FormData(formRef.current);
        const data = {};
        formData.forEach((value, key) => {
            if (key === 'file') {
                data[key] = value; // Append file directly
                return;
            }
            if (!data[key]) {
                data[key] = value;
                return;
            }
            if (!Array.isArray(data[key])) {
                data[key] = [data[key]];
            }
            data[key].push(value);
        });

        const authValue = apiParams?.auth;
        const servicesValue = apiParams?.services;

        if (!data['auth']) {
            data['auth'] = authValue;
        } else {
            if (!Array.isArray(data['auth'])) {
                data['auth'] = [data['auth']];
            }
            data['auth'].push(authValue);
        }

        if (!data['services']) {
            data['services'] = servicesValue;
        } else {
            if (!Array.isArray(data['services'])) {
                data['services'] = [data['services']];
            }
            data['services'].push(servicesValue);
        }

        console.log(data);

        var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'jamaah/save';
        const requestOptions = {
            method: 'POST',
            // Note: No need to set 'Content-Type' header for FormData
            body: formData, 
        };

        fetch(apiEndpoint, requestOptions)
        .then(async (response) => {
            var resJson = await response.json();

            if (resJson?.status) {

                debouncedFetchKotaKelahiran.cancel();
                debouncedFetchKotaAsal.cancel();
                debouncedFetchKecamatanAsal.cancel();
                debouncedFetchKelurahanAsal.cancel();

                history.push("/jamaah-haji")
                // setTimeout(() => {
                //     // Swal.fire("Successfuly", resJson?.message, "success").then(function() {
                //     //     history.push("/jamaah-umroh")
                //     // });
                // }, 1500);
            } else {
                Swal.fire('Analyzing Error', resJson?.message, 'error');
            }
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }

    const playerRef = useRef(null);

    const getJamaahDataEdit = async(jamaahId) => {
        if((typeof jamaahId != 'undefined') && (jamaahId != null) && (jamaahId != '')){

            var apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'jamaah/info';
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    auth: localStorage.getItem('rgd_token'),
                    services: localStorage.getItem('svc_token'),
                    jamaah_id: jamaahId
                }), 
            };
    
            fetch(apiEndpoint, requestOptions)
            .then(async (response) => {
                var resJson = await response.json();
    
                if (resJson?.status) {

                    const jamaah_id_val = resJson?.info?.jamaah_id
                    const tahunpendaftaran_val = resJson?.info?.tahunpendaftaran
                    const jamaah_first_name_val = resJson?.info?.jamaah_first_name
                    const jamaah_middle_name_val = resJson?.info?.jamaah_middle_name
                    const jamaah_last_name_val = resJson?.info?.jamaah_last_name
                    const jamaah_alhijaz_no_val = resJson?.info?.jamaah_alhijaz_no
                    const jamaah_tgl_lahir_val = resJson?.info?.jamaah_tgl_lahir
                    const kota_lahir_id_val = resJson?.info?.kota_lahir_id
                    const jamaah_jenkel_val = resJson?.info?.jamaah_jenkel
                    const jamaah_no_whatsapp_val = resJson?.info?.jamaah_no_whatsapp
                    const jamaah_status_nikah_val = resJson?.info?.jamaah_status_nikah
                    const kota_id_val = resJson?.info?.kota_id
                    const kecamatan_id_val = resJson?.info?.kecamatan_id
                    const kelurahan_id_val = resJson?.info?.kelurahan_id
                    const harga_paket_val = resJson?.info?.harga_paket
                    const perlengkapan_handling_val = resJson?.info?.perlengkapan_handling

                    const jamaah_jenis_haji_val = resJson?.info?.jamaah_jenis_haji

                    const kota_lahir_opt_val = resJson?.info?.kota_lahir_opt
                    const kota_asal_opt_val = resJson?.info?.kota_asal_opt
                    const kecamatan_asal_opt_val = resJson?.info?.kecamatan_asal_opt
                    const kelurahan_asal_opt_val = resJson?.info?.kelurahan_asal_opt

                    setJamaahId(jamaah_id_val)

                    setJenisHaji(jamaah_jenis_haji_val)

                    setOptionsKotaKelahiran(kota_lahir_opt_val)
                    setKotaKelahiranDefaultValue(kota_lahir_opt_val?.[0])

                    setOptionsKotaAsal(kota_asal_opt_val)
                    setKotaAsalDefaultValue(kota_asal_opt_val?.[0])

                    setOptionsKecamatanAsal(kecamatan_asal_opt_val)
                    setKecamatanAsalDefaultValue(kecamatan_asal_opt_val?.[0])

                    setOptionsKelurahanAsal(kelurahan_asal_opt_val)
                    setKelurahanAsalDefaultValue(kelurahan_asal_opt_val?.[0])

                    // console.log('AUWW', kota_lahir_opt_val?.[0])

                    setJamaahFirstName(jamaah_first_name_val)
                    setJamaahMiddleName(jamaah_middle_name_val)
                    setJamaahLastName(jamaah_last_name_val)

                    setJamaahAlhijazNo(jamaah_alhijaz_no_val)
                    setJamaahNoWhatsapp(jamaah_no_whatsapp_val)
                    setJamaahStatusNikah(jamaah_status_nikah_val)

                    setDateBorn(new Date(jamaah_tgl_lahir_val))
                    setKotaKelahiranID(kota_lahir_id_val)
                    setJenkel(jamaah_jenkel_val)
                    setKotaAsalId(kota_id_val)
                    setKecamatanAsalId(kecamatan_id_val)
                    setKelurahanAsalId(kelurahan_id_val)

                }
            })
            .then((result) => console.log(result))
            .catch((error) => console.error(error));
        }
    }

    const listTahunHijri = () => {
        const tdate = new Date()
        const yearGeo = tdate.getFullYear()
        const monthGeo = tdate.getMonth() + 1
        const dayGeo = tdate.getDate()
        const date = moment(`${yearGeo}-${monthGeo}-${dayGeo}`, 'YYYY-MM-DD');
        const maxhijrinow = date.iYear();

        
        // console.log('geoDate', `${yearGeo}-${monthGeo}-${dayGeo}`)
        // console.log('maxhijrinow', maxhijrinow)

        const listYearHijriTmp = []

        for(let i=(maxhijrinow-2);i<=maxhijrinow;i++){
            listYearHijriTmp.push({
                key: i,
                value: i + ' H',
                enabled: ((i >= maxhijrinow) ? true : false),
                selected: ((i == maxhijrinow) ? true : false)
            })
        }

        setListYearHijri(listYearHijriTmp)
        // console.log('listYearHijriTmp', listYearHijriTmp)
    }

    

    useEffect(() => {

        dispatch(updateTopNavTitle({
            title: (((typeof idParams != 'undefined') && (idParams != null) && (idParams != '')) ? 'Edit' : 'Tambah')+' Data Jamaah',
            actions: {}
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        listTahunHijri()
        getJamaahDataEdit(idParams)

        return () => {
            // Clean up debounce function on unmount
            debouncedFetchKotaKelahiran.cancel();
            debouncedFetchKotaAsal.cancel();
            debouncedFetchKecamatanAsal.cancel();
            debouncedFetchKelurahanAsal.cancel();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, toggleCollapsedNav])
    return (
        <>
        <Form ref={formRef} onSubmit={handleSubmitAddManual}>
            <Form.Control type='hidden' name='jamaah_id' id='jamaah_id' className='jamaah_id' value={jamaahId}/>
            <Form.Control type='hidden' name='auth' id='auth' className='auth' value={localStorage.getItem('rgd_token')}/>
            <Form.Control type='hidden' name='services' id='services' className='services' value={localStorage.getItem('svc_token')}/>
            <div className='p-3 pt-0 mt-3'>
                <div class="title-lg mb-3">Biodata</div>
                <div className='row'>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validateTahunPendaftaran">
                            <Form.Label htmlFor="tahunpendaftaran">Tahun Pendaftaran</Form.Label>
                            <Form.Select role='button' name='tahunpendaftaran'>
                                <option value={''}>-- Pilih Tahun --</option>
                                {listYearHijri.map((value, index) => (
                                    <option disabled={!value?.enabled} selected={value?.selected} value={value?.key}>{value?.value}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionIDAiw">
                            <Form.Label htmlFor="jamaah_alhijaz_no">ID Haji (HAJJ)</Form.Label>
                            <Form.Control defaultValue={jamaahAlhijazNo} type="text" name='jamaah_alhijaz_no' id="jamaah_alhijaz_no" aria-describedby="validataionIDAiwHelpBlock" placeholder='HAJJxxx' required={true}/>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionIDAiw">
                            <Form.Label htmlFor="jamaah_jenis_haji">Jenis Haji</Form.Label>
                            <Form.Select role='button' name='jamaah_jenis_haji'>
                                <option value={''}>-- Pilih Jenis --</option>
                                <option selected={(jenisHaji == 'kemenag') ? true : false} value={'kemenag'}>Kemenag</option>
                                <option selected={(jenisHaji == 'amitra') ? true : false} value={'amitra'}>Amitra</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validateJamaahNamaDepan">
                            <Form.Label htmlFor="jamaah_first_name">Nama Depan Jamaah</Form.Label>
                            <Form.Control defaultValue={jamaahFirstName} type="text" name='jamaah_first_name' id="jamaah_first_name" aria-describedby="validateJamaahNamaDepanHelpBlock" placeholder='Nama Depan' required={true}/>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validateJamaahNamaTengah">
                            <Form.Label htmlFor="jamaah_middle_name">Nama Tengah Jamaah</Form.Label>
                            <Form.Control defaultValue={jamaahMiddleName} type="text" name='jamaah_middle_name' id="jamaah_middle_name" aria-describedby="validateJamaahNamaTengahHelpBlock" placeholder='Nama Tengah' required={true}/>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validateJamaahNamaBelakang">
                            <Form.Label htmlFor="jamaah_last_name">Nama Belakang Jamaah</Form.Label>
                            <Form.Control defaultValue={jamaahLastName} type="text" name='jamaah_last_name' id="jamaah_last_name" aria-describedby="validateJamaahNamaBelakangHelpBlock" placeholder='Nama Belakang' required={true}/>
                        </Form.Group>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionDateBorn">
                            <Form.Label htmlFor="jamaah_tgl_lahir">Tanggal Lahir</Form.Label>
                            <Form.Control type='hidden' value={dateBorn} name='jamaah_tgl_lahir' className='jamaah_tgl_lahir' id='jamaah_tgl_lahir'/> 
                            <DateRangePicker
                                initialSettings={{
                                    timePicker: false,
                                    singleDatePicker: true,
                                    showDropdowns: false,
                                    startDate: dateBorn,
                                    locale: {
                                        format: 'YYYY-MM-DD',
                                    },
                                }}
                                onApply={(event, picker) => {
                                    setDateBorn(new Date(picker.startDate));
                                }}
                            >
                            <Form.Control type="text" name="single-date-pick1" />
                        </DateRangePicker>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionJamaahStatusNikah">
                            <Form.Label htmlFor="jamaah_status_nikah">Status Nikah</Form.Label>
                            <Form.Select role='button' name='jamaah_status_nikah'>
                                <option value={''}>-- Pilih Status --</option>
                                <option selected={(jamaahStatusNikah == 'belum_menikah') ? true : false} value={'belum_menikah'}>Belum Menikah</option>
                                <option selected={(jamaahStatusNikah == 'menikah') ? true : false} value={'menikah'}>Menikah</option>
                                <option selected={(jamaahStatusNikah == 'bercerai') ? true : false} value={'bercerai'}>Janda/Duda</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionKotaKelahiran">
                            <Form.Label htmlFor="kota_lahir_id">Kota Kelahiran</Form.Label>
                            <Form.Control type='hidden' value={kotaKelahiranID} name='kota_lahir_id' className='kota_lahir_id' id='kota_lahir_id'/> 
                            <Select
                                isLoading={loadingKotaKelahiran}
                                // loadOptions={loadKotaKelahiran}
                                options={optionsKotaKelahiran}
                                onInputChange={handleSearchKotaKelahiran}
                                onChange={(selected) => {
                                    // console.log('Selected option:', selected?.value);
                                    setKotaKelahiranID(selected?.value)
                                    setKotaKelahiranDefaultValue(selected)
                                }}
                                value={kotaKelahiranDefaultValue}
                                cacheOptions
                                defaultOptions
                                placeholder="Search..."
                            />
                        </Form.Group>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionReminderJamaahWaNumber">
                            <Form.Label htmlFor="jamaah_wa_number">Jenis Kelamin</Form.Label>
                            <div className='d-flex gap-3'>
                                <Form.Check
                                    label="Laki-laki"
                                    name="jamaah_jenkel"
                                    type={'radio'}
                                    value={'pria'}
                                    checked={(jenkel == 'pria') ? true : false}
                                    onClick={(e) => {
                                        setJenkel(e.target.value);
                                    }}
                                    id={'jamaah_jenkel_pria'}
                                    role='button'
                                />
                                <Form.Check
                                    label="Perempuan"
                                    name="jamaah_jenkel"
                                    type={'radio'}
                                    value={'wanita'}
                                    onClick={(e) => {
                                        setJenkel(e.target.value);
                                    }}
                                    checked={(jenkel == 'wanita') ? true : false}
                                    id={'jamaah_jenkel_wanita'}
                                    role='button'
                                />
                            </div>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionReminderJamaahWaNumber">
                            <Form.Label htmlFor="jamaah_no_whatsapp">No. Telp / WA Jamaah</Form.Label>
                            <Form.Control type="text" defaultValue={jamaahNoWhatsapp} name='jamaah_no_whatsapp' id="jamaah_no_whatsapp" aria-describedby="remindereJamaahWaNumberHelpBlock" placeholder='628777xxxxxx' required={true}/>
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionReminderJamaahWaNumber">
                            <Form.Label htmlFor="foto_ktp">Foto KTP</Form.Label>
                            <Form.Control type="file" onChange={(e) => cek_size(e)} accept='image/png, image/jpeg, application/pdf' name='foto_ktp' id='foto_ktp' className='foto_ktp'/>
                        </Form.Group>
                    </div>
                    
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionKotaAsal">
                            <Form.Label htmlFor="kota_id">Kota</Form.Label>
                            <Form.Control type='hidden' value={kotaAsalId} name='kota_id' className='kota_id' id='kota_id'/> 
                            <Select
                                isLoading={loadingKotaAsal}
                                loadOptions={loadKotaAsal}
                                options={optionsKotaAsal}
                                onInputChange={handleSearchKotaAsal}
                                onChange={(selected) => {
                                    // console.log('Selected option:', selected?.value);
                                    setKotaAsalId(selected?.value)
                                    setKotaAsalDefaultValue(selected)
                                }}
                                value={kotaAsalDefaultValue}
                                cacheOptions
                                defaultOptions
                                placeholder="Search..."
                            />
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionKecamatanAsal">
                            <Form.Label htmlFor="kecamatan_id">Kecamatan</Form.Label>
                            <Form.Control type='hidden' value={kecamatanAsalId} name='kecamatan_id' className='kecamatan_id' id='kecamatan_id'/> 
                            <Select
                                isLoading={loadingKecamatanAsal}
                                loadOptions={loadKecamatanAsal}
                                options={optionsKecamatanAsal}
                                onInputChange={handleSearchKecamatanAsal}
                                onChange={(selected) => {
                                    // console.log('Selected option:', selected?.value);
                                    setKecamatanAsalId(selected?.value)
                                    setKecamatanAsalDefaultValue(selected)
                                }}
                                value={kecamatanAsalDefaultValue}
                                cacheOptions
                                defaultOptions
                                placeholder="Search..."
                            />
                        </Form.Group>
                    </div>
                    <div className='col-md-4'>
                        <Form.Group className='mb-3' controlId="validataionKelurahanAsal">
                            <Form.Label htmlFor="kelurahan_id">Kelurahan</Form.Label>
                            <Form.Control type='hidden' value={kelurahanAsalId} name='kelurahan_id' className='kelurahan_id' id='kelurahan_id'/> 
                            <Select
                                isLoading={loadingKelurahanAsal}
                                loadOptions={loadKelurahanAsal}
                                options={optionsKelurahanAsal}
                                onInputChange={handleSearchKelurahanAsal}
                                onChange={(selected) => {
                                    // console.log('Selected option:', selected?.value);
                                    setKelurahanAsalId(selected?.value)
                                    setKelurahanAsalDefaultValue(selected)
                                }}
                                value={kelurahanAsalDefaultValue}
                                cacheOptions
                                defaultOptions
                                placeholder="Search..."
                            />
                        </Form.Group>
                    </div>
                </div>

                <div className='mt-3 d-flex gap-2'>
                    <Button className='fs-7' onClick={(e) => {
                        history.push("/jamaah-haji")
                    }} variant="light" type='button'>
                        Kembali
                    </Button>
                    <Button className='fs-7' variant="primary" type='submit' name='create_new_jamaah' value={1}>
                        Simpan Jamaah
                    </Button>
                </div>
            </div>
        </Form>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(JamaahHajiEditor);