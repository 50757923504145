import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';

import { colHaji } from '../Haji/DraftTableData';
import YogiTabler from '../../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const HajiIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const playerRef = useRef(null);
    const [rowData, setRowData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedEditor, ChooseEditor] = useState('unlayer');
    const formRef = useRef();

    const [show, setShow] = useState(false)

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'jamaah/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

    const customhandlers = (data) => {
        //any handler if data loaded
    }

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Jamaah Haji',
            actions: {
                title: 'Tambah Jamaah',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: (e) => {
                    history.push("/create-jamaah-haji")
                }
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className='p-3 pt-0 mt-3'>
                <YogiTabler
                    column={colHaji}
                    rowData={rowData}
                    rowsPerPage={10}
                    rowSelection={true}
                    markStarred={false}
                    searchQuery={searchTerm}
                    classes="nowrap w-100 mb-4"
                    api_endpoint={apiEndpoint}
                    api_params={{
                        'auth': apiParams?.auth,
                        'services': apiParams?.services,
                        'section': 'all',
                        'type' : 'haji'
                    }}
                    customhandlers={customhandlers}
                    emptydataconfig={{
                        heading: 'Anda belum memiliki data Jamaah',
                        subheading: "Klik tombol Tambah Jamaah untuk input data Jamaah baru.",
                        actions: {
                            className: 'btn btn-primary',
                            title: 'Tambah Jamaah',
                            onclick: (elem) => {
                                history.push("/create-jamaah-haji")
                            }
                        }
                    }}
                    sortFields={[
                        {
                            title: 'Email',
                            field: 'email'    
                        },
                        {
                            title: 'Status',
                            field: 'status'    
                        }
                    ]}
                    responsive
                />

            </div>

        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(HajiIndex);