import * as Icons from 'tabler-icons-react';
import * as TablerIcons from '@tabler/icons-react';

import HkBadge from '../../components/@hk-badge/@hk-badge';

export const SidebarMenu = [
    {
        group: '',
        contents: [
            {
                name: 'Reminder',
                icon: <Icons.Alarm />,
                path: '/dashboard',
            },
            {
                name: 'Jamaah Umroh',
                icon: <Icons.Users />,
                path: '/jamaah-umroh',
                subPath: ['/create-jamaah-umroh', '/edit-jamaah-umroh']
            },
            {
                name: 'Jamaah Haji',
                icon: <Icons.Users />,
                path: '/jamaah-haji',
                subPath: ['/create-jamaah-haji', '/edit-jamaah-haji']
            },
            {
                name: 'Kalkulasi',
                icon: <Icons.Calculator />,
                path: '/kalkulasi'
            },
            {
                name: 'Jadwal',
                icon: <Icons.Calendar />,
                path: '/jadwal'
            },
            {
                name: 'Daftar Kontak',
                icon: <Icons.Book />,
                path: '/contacts'
            },
            {
                name: 'Koneksi WhatsApp',
                icon: <Icons.BrandWhatsapp />,
                path: '/whatsapp'
            },
            {
                name: 'Akun Saya',
                icon: <Icons.Settings />,
                path: '/account'
            }
        ],
    }

]