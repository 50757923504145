import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Form, InputGroup, Nav, Row, Tab } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../../redux/action/Theme';
import { updateTopNavTitle } from '../../../redux/action/Header';
// import HkDataTable from '../../../components/@hk-data-table';
import YogiTabler from '../../../components/@yogi-data-table';
import { colLead } from './DraftTableData';
import { Button, Dropdown } from 'react-bootstrap';
import { useParams, useHistory } from 'react-router-dom';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');


const JamaahIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    const playerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [key, setKey] = useState('all');
    const [rowData, setRowData] = useState([]);

    const apiEndpoint = process.env.REACT_APP_URL_VITALS_API + 'jamaah/lists';
    const apiParams = {
        'auth': localStorage.getItem('rgd_token'),
        'services': localStorage.getItem('svc_token'),
    }

  

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Jamaah Umroh',
            actions: {
                title: 'Tambah Jamaah',
                classlists: 'btn btn-primary',
                icons: '',
                onclick: () => {
                    history.push("/create-jamaah-umroh")
                }
            }
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();
        // eslint-disable-next-line react-hooks/exhaustive-deps


    }, [])
    return (
        <>
            <div>
                <Tab.Container activeKey={key}>
                    {/* Page Header */}
                    <div className="hk-pg-header pg-header-wth-tab pt-0">
                        <Nav variant="tabs" className="nav-light nav-line nav nav-tabs mt-1">
                            <Nav.Item>
                                <Nav.Link eventKey="all" onClick={(e) => setKey('all')}>
                                    <span className="nav-link-text">Semua</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="dephmin1" onClick={(e) => setKey('dephmin1')}>
                                    <span className="nav-link-text">Berangkat H-1</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="rethmin1" onClick={(e) => setKey('rethmin1')}>
                                    <span className="nav-link-text">Pulang H+1</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* /Page Header */}
                    {/* Page Body */}
                    <div className="hk-pg-body p-0 m-0">
                        <Tab.Content>
                            <Tab.Pane eventKey="all">
                                <YogiTabler
                                    column={colLead}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'Anda belum memiliki data Jamaah',
                                        subheading: "Klik tombol Tambah Jamaah untuk input data Jamaah baru.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'Tambah Jamaah',
                                            onclick: (elem) => {
                                                history.push("/create-jamaah-umroh")
                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'all',
                                        'type' : 'umroh'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'No WhatsApp',
                                            field: 'no_whatsapp'    
                                        },
                                        {
                                            title: 'No Paspor',
                                            field: 'no_paspor'    
                                        },
                                        {
                                            title: 'Paket',
                                            field: 'package'    
                                        },
                                        {
                                            title: 'Kota',
                                            field: 'city'    
                                        },
                                        {
                                            title: 'Tanggal Berangkat',
                                            field: 'departure_date'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="dephmin1">
                            <YogiTabler
                                    column={colLead}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'Anda belum memiliki data Jamaah',
                                        subheading: "Klik tombol Tambah Jamaah untuk input data Jamaah baru.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'Tambah Jamaah',
                                            onclick: (elem) => {
                                                history.push("/create-jamaah-umroh")
                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'dephmin1',
                                        'type' : 'umroh'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'No WhatsApp',
                                            field: 'no_whatsapp'    
                                        },
                                        {
                                            title: 'No Paspor',
                                            field: 'no_paspor'    
                                        },
                                        {
                                            title: 'Paket',
                                            field: 'package'    
                                        },
                                        {
                                            title: 'Kota',
                                            field: 'city'    
                                        },
                                        {
                                            title: 'Tanggal Berangkat',
                                            field: 'departure_date'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="rethmin1">
                            <YogiTabler
                                    column={colLead}
                                    rowData={rowData}
                                    rowsPerPage={10}
                                    rowSelection={true}
                                    markStarred={false}
                                    searchQuery={searchTerm}
                                    classes="nowrap w-100 mb-4"
                                    api_endpoint={apiEndpoint}
                                    emptydataconfig={{
                                        heading: 'Anda belum memiliki data Jamaah',
                                        subheading: "Klik tombol Tambah Jamaah untuk input data Jamaah baru.",
                                        actions: {
                                            className: 'btn btn-primary',
                                            title: 'Tambah Jamaah',
                                            onclick: (elem) => {
                                                history.push("/create-jamaah-umroh")
                                            }
                                        }
                                    }}
                                    api_params={{
                                        'auth': apiParams?.auth,
                                        'services': apiParams?.services,
                                        'section': 'rethmin1',
                                        'type' : 'umroh'
                                    }}
                                    sortFields={[
                                        {
                                            title: 'Name',
                                            field: 'name'    
                                        },
                                        {
                                            title: 'No WhatsApp',
                                            field: 'no_whatsapp'    
                                        },
                                        {
                                            title: 'No Paspor',
                                            field: 'no_paspor'    
                                        },
                                        {
                                            title: 'Paket',
                                            field: 'package'    
                                        },
                                        {
                                            title: 'Kota',
                                            field: 'city'    
                                        },
                                        {
                                            title: 'Tanggal Berangkat',
                                            field: 'departure_date'    
                                        }
                                    ]}
                                    responsive
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                    {/* /Page Body */}
                </Tab.Container>
            </div>
        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(JamaahIndex);