import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Col, Container, Form, InputGroup, Nav, Row, Tab, Modal, Button } from 'react-bootstrap';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { connect, useDispatch } from 'react-redux';
import { toggleCollapsedNav } from '../../redux/action/Theme';
import { updateTopNavTitle } from '../../redux/action/Header';
import classNames from 'classnames';
import Swal from 'sweetalert2';

import { colHaji } from './DraftTableData';
import YogiTabler from '../../components/@yogi-data-table';

import { Player } from '@lordicon/react';
const SMILEJson = require('./iconjson/smile.json');

const KontakIndex = ({ navCollapsed, toggleCollapsedNav }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const playerRef = useRef(null);

    useEffect(() => {
        dispatch(updateTopNavTitle({
            title: 'Kontak',
            actions: {}
        }));
        toggleCollapsedNav(false);
        playerRef.current?.playFromBeginning();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <div className='p-3 pt-0 mt-3'>

            </div>

        </>
    )
}

// export default Dashboard
const mapStateToProps = ({ theme }) => {
    const { navCollapsed } = theme;
    return { navCollapsed }
};

export default connect(mapStateToProps, { toggleCollapsedNav })(KontakIndex);